import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar';
import PageTitle from '../../components/pagetitle';
import Scrollbar from '../../components/scrollbar';
import boletin from '../../api/boletin';
import BlogSingle from '../../components/BoletinDetails/boletin1';
import Footer from '../../components/footer';
import Logo from '../../images/logo.svg';



import { Helmet } from'react-helmet';

const BlogDetails = () => {
  const BlogDetails = boletin.find((item) => item.id === '1');

  return (
    <Fragment>
      <Helmet>
        <title>Ley Karin y acoso laboral - H&A Abogados</title>
        <meta
          name="description"
          content="Análisis y discusión sobre la Ley Karin y su impacto en el acoso laboral."
        />
      </Helmet>
      <Navbar
        hclass={'wpo-header-style-2'}
        Logo={Logo}
        topbarNone={'header-without-topbar'}
      />
      <PageTitle pageTitle={BlogDetails.title} pagesub={'Boletin'} />
      <BlogSingle />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default BlogDetails;
