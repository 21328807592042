import React from 'react';
import Routing from './Routing';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import './sass/style.scss';

const App = () => {
  return (
    <div className="App" id="scrool">
      <Routing />
      <ToastContainer />
    </div>
  );
};

export default App;
