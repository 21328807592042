import React from 'react';
import { Link } from 'react-router-dom'
import Author from '../../images/blog/blog-avater/Author1.jpg'
import blogs from '../../api/blogs';
import BlogSidebar from '../BlogSidebar'

const BlogSingle = (props) => {


    const BlogDetails = blogs.find(item => item.id === '2')

    const ClickHandler = () => {
      window.scrollTo(10, 0);
    };


    return (
      <section className="wpo-blog-single-section section-padding">
        <div className="container">
          <div className="row">
            <div className={`col col-lg-8 col-12 ${props.blRight}`}>
              <div className="wpo-blog-content">
                <div className="post format-standard-image">
                  <div className="entry-media">
                    <img src={BlogDetails.blogSingleImg} alt="" />
                  </div>
                  <div className="entry-meta">
                    <ul>
                      <li>
                        <i className="fi flaticon-user"></i> By{' '}
                        <Link to="/equipo/1">
                          <strong>{BlogDetails.author}</strong>
                        </Link>{' '}
                      </li>
                      <li>
                        <i className="fi flaticon-comment-white-oval-bubble"></i>{' '}
                        Tiempo de Lectura {BlogDetails.comment}
                      </li>
                      <li>
                        <i className="fi flaticon-calendar"></i>{' '}
                        {BlogDetails.create_at}
                      </li>
                    </ul>
                  </div>
                  <h2>
                    El <span>due diligence</span> como elemento revelador de
                    contingencias.
                  </h2>
                  <p>
                    Un empresario nacional, tiene la intención de importar el
                    exclusivo grano de café Kona de Hawái. Inicialmente, nos
                    informa que tiene un potencial exportador de este producto y
                    que cuenta con diversos canales de distribución en el país.
                    Adicionalmente, nuestro prospecto posee una tentadora marca
                    comercial que quiere registrar a la brevedad. Sin embargo,
                    su mayor apuro, es comprar en el menor tiempo posible, un
                    establecimiento comercial, ubicado en un exclusivo y
                    concurrido barrio de Santiago. Su objetivo, golpear
                    rápidamente el mercado con su novedoso producto.
                  </p>
                  <p>
                    Como es de esperarse, las expectativas de los emprendedores
                    siempre son altas. La creación y formación de un proyecto,
                    reporta un significativo desafío, que involucra – en muchos
                    casos – sueños y afectos. Pero, cuando se cuenta con una
                    considerable fuente de financiamiento, esta puede nublar la
                    visión del inversionista, quien intentará acelerar el
                    proceso, desconociendo todas las aristas comerciales,
                    contables o jurídicas que debe ponderar antes de invertir.
                    El entusiasmo sin tregua, brota incluso en un desierto de
                    antecedentes.
                  </p>
                  <p>
                    ¿Cómo podemos aterrizar este despegue? con esta
                    interrogante, nace el “Due Diligencie” como solución.
                  </p>
                  <p>
                    Con ello, por medio de un específico informe, se realiza una
                    investigación del negocio del cliente. Tomaremos en
                    consideración las áreas comerciales, financieras, legales y
                    de planificación tributaria para el propósito. Lo anterior,
                    con el objetivo de evaluar todos los flujos y factores que
                    puedan tener impacto en el futuro del proyecto. Determinando
                    de esta manera, el precio de la contingencia. Lo importante,
                    es tomar una decisión debidamente informada.
                  </p>
                  <p>
                    En el caso sublite propuesto, se analiza la relación
                    comercial y contractual con los proveedores de café.
                    Determinando la constitución de una figura societaria
                    adecuada para el cliente. Para esto se investigará en
                    detalle los posibles accionistas o socios de la futura
                    empresa. Procurando siempre erradicar todo riesgo que pueda
                    dañar la operación.
                  </p>
                  <p>
                    Dentro del marco regulatorio del negocio, se obtendrá una
                    hoja de ruta para toda la tramitación administrativa que se
                    requiera para una adecuada apertura y puesta en marcha del
                    proyecto. Se estudiará y se materializará la solicitud de
                    inscripción de la marca comercial propuesta, ejecutando
                    previamente un análisis de factibilidad de la marca, antes
                    del requerimiento ante INAPI. No queremos que nuestro
                    prospecto invierta en publicidad, sin revolver esa
                    significativa arista de su propósito.
                  </p>
                  <p>
                    En la esfera inmobiliaria, será necesario estudiar el título
                    de dominio que involucre el establecimiento comercial que se
                    intenta comprar. Lo señalado se elaborara, revisando la
                    titularidad del inmueble y verificando que no existan
                    gravámenes o litigios asociados a la propiedad pretendida.
                    Por tanto, será vital descartar deudas asociadas al inmueble
                    que puedan sorprender a nuestro cliente durante el
                    funcionamiento de su empresa.
                  </p>
                  <p>
                    Como se puede observar, solo con los puntos expuestos, queda
                    claro que los elementos mencionados son de alta utilidad
                    para las pretensiones del cliente. Esto permite que nuestro
                    prospecto pueda modificar los términos económicos de la
                    transacción, revisar la estructura de riegos asociados al
                    proyecto, modificar la parcial o totalmente el negocio o
                    derechamente desistirse de este. Queremos que su aventura
                    empresarial sea pacífica y sin incertidumbres.
                  </p>
                  <p>
                    Por último, será relevante no solo recopilar y exhibir los
                    antecedentes rescatados a nuestro futuro inversionista, sino
                    que principalmente orientarlo. ¿De qué manera? fijando
                    conclusiones, alternativas y recomendaciones de los
                    hallazgos revelados. Aclarando el marco jurídico del negocio
                    y los efectos post operación del proyecto. Lo indicado,
                    logrará diluir toda incertidumbre existente. Se aterrizarán
                    las expectativas y se tomará una decisión con certezas. Todo
                    esto, gracias al “Due diligence”, un instrumento
                    multidisciplinario que invita a la reflexión del
                    inversionista, en la toma de decisiones.
                  </p>
                </div>

                <div className="tag-share clearfix">
                  <div className="tag">
                    <span>Tags </span>
                    <ul>
                      <li>
                        <Link>DUE DILIGENCE</Link>
                      </li>
                      <li>
                        <Link>INFORMACIÓN FINANCIERA</Link>
                      </li>
                      <li>
                        <Link>INVERSIONISTAS</Link>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="author-box">
                  <div className="author-avatar">
                    <Link to="/equipo/1" target="_blank">
                      <img src={Author} alt="" />
                    </Link>
                  </div>
                  <div className="author-content">
                    <Link
                      onClick={ClickHandler}
                      to="/equipo/1"
                      className="author-name"
                    >
                      Pablo Hernández Arias
                    </Link>
                    <p>H&A Abogados</p>
                    <div className="socials">
                      <ul className="social-link">
                        <li>
                          <Link to="https://www.linkedin.com/in/pablo-hern%C3%A1ndez-arias-hya/">
                            <i className="ti-linkedin"></i>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="more-posts">
                  <div className="previous-post">
                    <Link
                      onClick={ClickHandler}
                      to="/opinion/la-importancia-de-un-asesor-legal-en-establecimientos-educacionales"
                    >
                      <span className="post-control-link">Anterior</span>
                      <span className="post-name">
                        La importancia de un “Asesor Legal” en establecimientos
                        educacionales.
                      </span>
                    </Link>
                  </div>
                  <div className="next-post">
                    <Link
                      onClick={ClickHandler}
                      to="/opinion/como-prevenir-y-proteger-la-salud-mental-de-sus-trabajadores"
                    >
                      <span className="post-control-link">Siguiente</span>
                      <span className="post-name">
                        ¿Cómo prevenir y proteger la salud mental de sus
                        trabajadores en los fuegos de una cocina?
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <BlogSidebar blLeft={props.blLeft} />
          </div>
        </div>
      </section>
    );

}

export default BlogSingle;
