import React from 'react';
import { Link } from 'react-router-dom'
import Author from '../../images/blog/blog-avater/Author1.jpg';
import blogs from '../../api/blogs';
import BlogSidebar from '../BlogSidebar'

const BlogSingle = (props) => {


    const BlogDetails = blogs.find(item => item.id === '1')

    const ClickHandler = () => {
      window.scrollTo(10, 0);
    };


    return (
      <section className="wpo-blog-single-section section-padding">
        <div className="container">
          <div className="row">
            <div className={`col col-lg-8 col-12 ${props.blRight}`}>
              <div className="wpo-blog-content">
                <div className="post format-standard-image">
                  <div className="entry-media">
                    <img src={BlogDetails.blogSingleImg} alt="" />
                  </div>
                  <div className="entry-meta">
                    <ul>
                      <li>
                        <i className="fi flaticon-user"></i> By{' '}
                        <Link to="/equipo/1">
                          <strong>{BlogDetails.author}</strong>
                        </Link>{' '}
                      </li>
                      <li>
                        <i className="fi flaticon-comment-white-oval-bubble"></i>{' '}
                        Tiempo de Lectura {BlogDetails.comment}
                      </li>
                      <li>
                        <i className="fi flaticon-calendar"></i>{' '}
                        {BlogDetails.create_at}
                      </li>
                    </ul>
                  </div>
                  <h2>
                    Como prevenir y proteger la salud mental de sus trabajadores
                  </h2>
                  <blockquote>
                    <p>
                      Creo que la presión es saludable y muy pocos pueden
                      manejarla. dijo alguna vez el reconocido chef
                      internacional Gordon Ramsay. Conocido por muchos, por ser
                      el anfitrión de entretenidos programas de cocina, que se
                      caracterizan por transformar los quemadores, en verdaderos
                      coliseos romanos del apremio gastronómico.
                    </p>
                  </blockquote>
                  <p>
                    Sin duda, la gastronomía es un mercado complejo. La
                    paciencia de los comensales, la alta demanda de un buen
                    restaurante o el exceso de comandas pendientes, pueden
                    tensionar el ambiente de trabajo en una cocina. La
                    temperatura y la continua contaminación acústica, son
                    factores relevantes para transformar esa jornada laboral en
                    un cúmulo de daño para los trabajadores del rubro culinario.
                  </p>
                  <p>
                    Sin embargo, es innegable que la costumbre del rubro puede
                    confundirnos. Es cierto, el trabajo generado fuera de los
                    comedores reporta una comunicación ardorosa. La pasión en
                    muchos casos se toma las cocinas y se normaliza un trato de
                    alto calibre. Gritos o insultos dominan ese espacio, pero
                    ese ambiente no debe ser el óptimo.
                  </p>
                  <p>
                    Ante esto, es necesario calmar los ánimos y observar con
                    detalle el ambiente laboral que se va generando en estos
                    puestos de trabajo. No todo mal trato verbal se puede
                    justificar con frases simplistas y displicentes. “Este rubro
                    es así” “Si no soporta la presión de la cocina, se debe
                    dedicar a otra cosa”. Lo concreto, es dar siempre
                    protección, a la salud mental de los trabajadores.
                  </p>
                  <p>
                    En concordancia armonía, no podemos olvidar el tenor literal
                    del inciso primero del artículo 184 del Código del Trabajo
                    “El empleador estará obligado a tomar todas las medidas
                    necesarias para proteger eficazmente la vida y salud de los
                    trabajadores, informando de los posibles riesgos y
                    manteniendo las condiciones adecuadas de higiene y seguridad
                    en las faenas, como también los implementos necesarios para
                    prevenir accidentes y enfermedades profesionales.”
                  </p>
                  <p>
                    Con lo expuesto, nace la siguiente pregunta ¿Qué
                    herramientas pueden utilizar las empresas gastronómicas para
                    prevenir y proteger la salud de sus trabajadores?
                  </p>
                  <p>
                    Las soluciones son variadas, pero me quiero centrar en
                    evitar el mal trato verbal que se le da – en variados casos
                    – al personal de cocina. Sobre todo, cuando este realiza una
                    larga epopeya culinaria, para “sacar” bajo presión las
                    preparaciones de cada jornada.
                  </p>
                  <p>
                    Por un lado, a nivel preventivo, es recomendable mantener
                    una fluida comunicación con la mutualidad a la cual usted se
                    encuentra suscrito. Con ello, no solo logrará que su
                    personal pueda ser capacitado en diversas materias de
                    seguridad durante el año, sino que ganará un levantamiento
                    de los riesgos asociados a su empresa. Esa herramienta
                    (matriz de riesgo), le permitirá determinar las
                    contingencias que existen actualmente en los puestos de
                    trabajo de su restaurante.
                  </p>
                  <p>
                    De manera adicional, es aconsejable encuestar constantemente
                    a su personal sobre el ambiente laboral en el que se
                    ejecutan sus funciones. Pero también es necesario,
                    centrarnos en las jefaturas. Administrativos, encargados de
                    logísticas y chef ejecutivos deben ser capacitados, al menos
                    una vez al año, en materias asociadas al acoso laboral o
                    mobbing laboral. Todo con el fin de evitar eventos adversos
                    futuros, que puedan atentar contra integridad de sus
                    trabajadores.
                  </p>
                  <p>
                    Asimismo, recomendamos contar o complementar Reglamentos
                    Internos de Orden Seguridad e Higiene. Sobre todo con
                    protocolos específicos de acción, ante situaciones de acoso
                    laboral o acoso sexual laboral. Documentos que por lo demás,
                    deben ser conocidos y manejados por la totalidad de la
                    empresa y con una aplicación efectiva.
                  </p>
                  <p>
                    En el mismo orden de ideas, no olvidar que todas estas las
                    medidas deben ser documentadas. Lo anterior, permitirá que
                    los empleadores cuenten con un respaldo de las medidas
                    aplicadas. Con esto, se mantendrán medios probatorios
                    contundentes, que los ayudarán a defenderse correctamente
                    ante un reclamo injustificado ante la autoridad
                    administrativa (Inspección del Trabajo). Más aún, si el
                    reclamo se judicializa, se tendrán elementos adecuados para
                    preparar una robusta defensa ante los tribunales de
                    justicia, cuando surja alguna artificiosa demanda laboral
                    por autodespido o tutela laboral de derechos en contra de la
                    empresa.
                  </p>
                  <p>
                    Finalmente, si logramos trabajar los puntos antes indicados,
                    estaremos en presencia de trabajadores sanos, trabajando en
                    un ambiente laboral agradable y erradicaremos toda
                    contingencia futura (que por lo demás son costosas para una
                    empresa). Lo importante, es poder transformar las cocinas en
                    un encantador centro gastronómico creativo y no en un
                    tentadora puesta en escena del programa “Hell’s Kitchen” del
                    ya mencionado chef Ramsey.
                  </p>
                </div>

                <div className="tag-share clearfix">
                  <div className="tag">
                    <span>Tags </span>
                    <ul>
                      <li>
                        <Link>COCINA</Link>
                      </li>
                      <li>
                        <Link>LEY LABORAL</Link>
                      </li>
                      <li>
                        <Link>SALUD MENTal</Link>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="author-box">
                  <div className="author-avatar">
                    <Link to="/equipo/1" target="_blank">
                      <img src={Author} alt="" />
                    </Link>
                  </div>
                  <div className="author-content">
                    <Link
                      onClick={ClickHandler}
                      to="/equipo/1"
                      className="author-name"
                    >
                      Pablo Hernández Arias
                    </Link>
                    <p>H&A Abogados</p>
                    <div className="socials">
                      <ul className="social-link">
                        <li>
                          <Link to="https://www.linkedin.com/in/pablo-hern%C3%A1ndez-arias-hya/">
                            <i className="ti-linkedin"></i>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="more-posts">
                  <div className="previous-post">
                    <Link
                      onClick={ClickHandler}
                      to="/opinion/el-due-diligence-como-elemento"
                    >
                      <span className="post-control-link">Anterior</span>
                      <span className="post-name">
                        El “due diligence” como elemento revelador de
                        contingencias.
                      </span>
                    </Link>
                  </div>
                  <div className="next-post">
                    <Link
                      onClick={ClickHandler}
                      to="/opinion/trato-escolar-ausente"
                    >
                      <span className="post-control-link">Siguiente</span>
                      <span className="post-name">
                        Todos unificados en la tarea de enriquecer la educación
                        abarcando cada condición.
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <BlogSidebar blLeft={props.blLeft} />
          </div>
        </div>
      </section>
    );

}

export default BlogSingle;
