import React from 'react';
import { Link } from 'react-router-dom';
import Author from '../../images/blog/blog-avater/Author2.jpg';
import boletin from '../../api/boletin';
import BlogSidebar from '../BlogSidebar';

const BoletinSingle = (props) => {
  const BlogDetails = boletin.find((item) => item.id === '1');

  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };


  return (
    <section className="wpo-blog-single-section section-padding">
      <div className="container">
        <div className="row">
          <div className={`col col-lg-8 col-12 ${props.blRight}`}>
            <div className="wpo-blog-content">
              <div className="post format-standard-image">
                <div className="entry-media">
                  <img src={BlogDetails.blogSingleImg} alt="" />
                </div>
                <div className="entry-meta">
                  <ul>
                    <li>
                      <i className="fi flaticon-user"></i> By{' '}
                      <Link to="/equipo/2">
                        <strong>{BlogDetails.author}</strong>
                      </Link>{' '}
                    </li>
                    <li>
                      <i className="fi flaticon-comment-white-oval-bubble"></i>{' '}
                      Tiempo de Lectura {BlogDetails.comment}
                    </li>
                    <li>
                      <i className="fi flaticon-calendar"></i>{' '}
                      {BlogDetails.create_at}
                    </li>
                  </ul>
                </div>
                <h2>Ley Karin</h2>
                <p>
                  La Ley Karin, viene a modificar el Código del Trabajo y otros
                  cuerpos legales, en materia de prevención, investigación y
                  sanción del acoso laboral, sexual o de violencia en el
                  trabajo. En definitiva, la norma busca robustecer la
                  legislación contra el acoso sexual, laboral y violencia en el
                  trabajo.
                </p>
                <p>
                  La ley establece que las relaciones laborales deben ser libres
                  de violencia y respetar la dignidad de las personas, así como
                  promover la inclusión y la igualdad de género.
                </p>
                <p>
                  Se consideran conductas contrarias: el acoso sexual y laboral,
                  también se prohíbe la violencia en el trabajo por parte de
                  terceros ajenos a la relación laboral, como clientes o
                  proveedores.
                </p>
                <p>
                  <span>
                    ¿Qué nuevos derechos viene a consagrar la normativa?
                  </span>
                </p>
                <p>
                  En el entorno laboral, los trabajadores tienen derecho a un
                  espacio libre de acoso sexual, laboral y violencia. Para
                  garantizar esto, los empleadores deben tomar medidas para
                  prevenir, investigar y sancionar estas conductas.
                </p>
                <p>
                  <span>¿Cuáles son las obligaciones del empleador?</span>
                </p>
                <ul className="ul">
                  <li className="li">
                    <p>
                      Se requiere que los empleadores elaboren un{' '}
                      <strong>Protocolo</strong> de prevención, ya sea de forma
                      directa o a través de los organismos administradores
                      correspondientes.
                    </p>
                  </li>
                  <li className="li">
                    <p>
                      El protocolo de prevención del acoso sexual, laboral y
                      violencia en el trabajo{' '}
                      <strong>
                        debe incluir la identificación y evaluación de los
                        riesgos psicosociales, con enfoque de género
                      </strong>
                      .
                    </p>
                  </li>
                </ul>
                <p>
                  Este último debe ser incluído en RIOSH, si la empresa por su
                  naturaleza, no cuenta con un RIOSH, al momento de suscribir el
                  contrato de trabajo, debe acompañar el Protocolo.
                </p>
                <ul className="ul">
                  <li className="li">
                    <p>
                      También deben establecerse{' '}
                      <strong>
                        medidas para prevenir y controlar estos riesgos
                      </strong>
                      , así como para informar y capacitar a los trabajadores
                      sobre las medidas de prevención y sus derechos y
                      responsabilidades.
                    </p>
                  </li>
                  <li className="li">
                    <p>
                      Deben considerar las{' '}
                      <strong>
                        medidas necesarias según la naturaleza de los servicios
                        prestados
                      </strong>{' '}
                      en relación a estas problemáticas.
                    </p>
                  </li>
                  <li className="li">
                    <p>
                      Deben <strong>informar semestralmente</strong> sobre los
                      canales de denuncia de acoso sexual, laboral y violencia
                      en el trabajo.
                    </p>
                  </li>
                  <li className="li">
                    <p>
                      También deben{' '}
                      <strong>
                        informar sobre las instancias estatales para denunciar
                        incumplimientos
                      </strong>{' '}
                      y acceder a prestaciones de seguridad social. La
                      Superintendencia de Seguridad Social, entregará
                      directrices.
                    </p>
                  </li>
                </ul>
                <p>
                  <span>
                    ¿Qué directrices entrega respecto a los procedimientos de
                    investigación, realizados por parte del empleador?
                  </span>
                </p>
                <p>
                  La ley indica que estos procedimientos deben cumplir con los
                  principios de confidencialidad, imparcialidad, celeridad y
                  perspectiva de género.
                </p>
                <p>
                  El Ministerio del Trabajo y Previsión Social, con la opinión
                  de la Dirección del Trabajo, establecerá las directrices para
                  las investigaciones mediante un Reglamento. (Art. 211 B)
                </p>
                <p>
                  <span>
                    ¿Qué puede hacer una persona que esté siendo afectada en su
                    entorno laboral?
                  </span>
                </p>
                <p>
                  En casos de acoso sexual, laboral o violencia en el trabajo,
                  la persona afectada debe presentar su denuncia por escrito o
                  verbalmente a la empresa o a la Inspección del Trabajo.
                </p>
                <p>
                  <span>¿Qué ocurre si la denuncia es verbal?</span>
                </p>
                <p>
                  El empleador debe levantar un acta que será firmada por la
                  persona denunciante y se le entregará una copia. Una vez
                  recibida la denuncia, el empleador deberá tomar medidas
                  inmediatas de protección considerando la gravedad de los
                  hechos, la seguridad de la persona denunciante y las
                  condiciones de trabajo.
                </p>
                <p>
                  <span>
                    ¿Qué ocurre si la denuncia es realizada ante la Inspección
                    del Trabajo?
                  </span>
                </p>
                <p>
                  Esta solicitará al empleador medidas de resguardo en un plazo
                  máximo de 2 días hábiles, que deben ser adoptadas de forma
                  inmediata una vez notificadas.
                </p>
                <p>
                  <span>
                    ¿Cual es el procedimiento a seguir si como empleador recibe
                    una denuncia?
                  </span>
                </p>
                <p>
                  Si la denuncia por acoso sexual, laboral o violencia en el
                  trabajo se presenta ante la empresa, esta debe realizar una
                  investigación interna en un plazo máximo de 30 días. Si no
                  opta por esta opción, la empresa debe remitir los antecedentes
                  a la Inspección del Trabajo en un plazo de 3 días.
                </p>
                <p>
                  La investigación interna debe ser documentada por escrito,
                  mantener total confidencialidad, escuchar a ambas partes y
                  permitirles expresar sus argumentos. Una vez concluida, se
                  deben enviar tanto los resultados como las conclusiones a la
                  Inspección del Trabajo correspondiente.
                </p>
                <p>
                  Las investigaciones a las que hace referencia el presente
                  artículo deberán ajustarse siempre a las directrices
                  establecidas en el Reglamento previamente aludido.
                </p>
                <p>
                  El empleador debe tomar las medidas o sanciones adecuadas
                  dentro de un plazo máximo de 15 días después de recibir el
                  informe de investigación en casos de acoso sexual y laboral
                </p>
                <p>
                  Si los hechos denunciados constituyen un delito, el empleador
                  debe entregar los antecedentes al Ministerio Público en un
                  plazo de 48 horas.
                </p>
                <p>
                  Además, el empleador está obligado a proporcionar información
                  sobre los canales de denuncia de posibles delitos en casos de
                  acoso sexual, laboral o violencia en el trabajo a la persona
                  que realizó la denuncia.
                </p>
                <p>
                  <span>¿Cuándo entra en vigencia esta ley ?</span>
                </p>
                <p>
                  Entrará en vigencia el primer día del 6° mes subsiguiente a su
                  publicación en el Diario Oficial. Recordemos que solo fue
                  Promulgada, queda pendiente su publicación en el Diario
                  Oficial y desde ese momento, es obligatoria y se presume
                  conocida por todos.
                </p>
              </div>

              <div className="tag-share clearfix">
                <div className="tag">
                  <span>Tags </span>
                  <ul>
                    <li>
                      <Link>Acoso laboral</Link>
                    </li>
                    <li>
                      <Link>Derecho del trabajo</Link>
                    </li>
                    <li>
                      <Link>Acoso sexual laboral</Link>
                    </li>
                    <li>
                      <Link>Ley Karin</Link>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="author-box">
                <div className="author-avatar">
                  <Link to="/equipo/2" target="_blank">
                    <img src={Author} alt="" />
                  </Link>
                </div>
                <div className="author-content">
                  <Link
                    onClick={ClickHandler}
                    to="/equipo/2"
                    className="author-name"
                  >
                    Daniela Sepúlveda Carvajal
                  </Link>
                  <p>
                    Este boletín ha sido elaborado por el equipo de
                    profesionales de H & A abogados, con la intención de
                    proporcionar información educativa. Sin embargo , les
                    recordamos que la presente no debe considerarse como
                    asesoramiento legal, sino como una guía general.
                  </p>
                  <div className="socials">
                    <ul className="social-link">
                      <li>
                        <Link to="https://www.linkedin.com/in/daniela-sep%C3%BAlveda-carvajal/">
                          <i className="ti-linkedin"></i>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="more-posts">
                <div className="previous-post">
                  <Link
                    onClick={ClickHandler}
                    to="/boletin/ley-karin-acoso-laboral"
                  >
                    <span className="post-control-link">Anterior</span>
                    <span className="post-name">Ley Karin</span>
                  </Link>
                </div>
                <div className="next-post">
                  <Link
                    onClick={ClickHandler}
                    to="/boletin/ley-karin-acoso-laboral"
                  >
                    <span className="post-control-link">Siguiente</span>
                    <span className="post-name">Ley Karin</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <BlogSidebar blLeft={props.blLeft} />
        </div>
      </div>
    </section>
  );
};

export default BoletinSingle;
