import React, {Fragment} from 'react';
import Navbar from '../../components/Navbar'
import PageTitle from '../../components/pagetitle'
import Error from '../../components/404'
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
import Logo from '../../images/logo.svg'

import { Helmet } from'react-helmet';

const ErrorPage =() => {
    return (
      <Fragment>
        <Helmet>
          <title>Página no encontrada - H&A Abogados</title>
        </Helmet>
        <Navbar
          hclass={'wpo-header-style-2'}
          Logo={Logo}
          topbarNone={'header-without-topbar'}
        />
        <PageTitle pageTitle={'404'} pagesub={'404'} />
        <Error />
        <Footer />
        <Scrollbar />
      </Fragment>
    );
};
export default ErrorPage;

