import React, { Component } from 'react';
import emailjs from '@emailjs/browser';
import Swal from 'sweetalert2';

class ContactForm extends Component {
  state = {
    name: '',
    email: '',
    subject: '',
    phone: '',
    events: '',
    notes: '',
    error: {},
  };

  componentDidMount() {
    emailjs.init('X9RXJbAaYa_9JRWWY');
  }

  changeHandler = (e) => {
    const error = this.state.error;
    error[e.target.name] = '';

    this.setState({
      [e.target.name]: e.target.value,
      error,
    });
  };

  submitHandler = (e) => {
    e.preventDefault();
    console.log('submitHandler ejecutado'); 

    const { name, email, subject, phone, events, notes, error } = this.state;

    if (name === '') error.name = 'Please enter your name';
    if (email === '') error.email = 'Please enter your email';
    if (subject === '') error.subject = 'Please enter your subject';
    if (phone === '') {
      error.phone = 'Please enter your phone number';
    } else if (!/^\d+$/.test(phone)) {
      error.phone = 'Phone number must contain only numbers';
    }
    
    if (notes === '') error.notes = 'Please enter your note';

    if (Object.values(error).some((err) => err !== '')) {
      this.setState({ error });
    } else {
      // Enviar email utilizando EmailJS
      const templateParams = {
        from_name: name,
        reply_to: email,
        subject: subject,
        phone: phone,
        events: events,
        message: notes,
      };

      emailjs.send('EstudioHyA', 'template_estudiohya', templateParams).then(
        () => {
          // Notificación de éxito
          Swal.fire({
            icon: 'success',
            title: '¡Mensaje Enviado!',
            text: 'Tu mensaje ha sido enviado exitosamente. Nos pondremos en contacto contigo pronto.',
            confirmButtonText: 'Cerrar',
          });

          this.setState({
            name: '',
            email: '',
            subject: '',
            phone: '',
            events: '',
            notes: '',
            error: {},
          });
        },
        (error) => {
          // Notificación de error
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Hubo un problema al enviar tu mensaje. Por favor, intenta de nuevo.',
            confirmButtonText: 'Cerrar',
          });

          console.log('Failed to send email...', error);
        },
      );
    }
  };

  render() {
    const { name, email, subject, phone, notes, error } = this.state;

    return (
      <form onSubmit={this.submitHandler} className="form">
        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-6 col-12">
            <div className="form-field">
              <input
                value={name}
                onChange={this.changeHandler}
                type="text"
                name="name"
                placeholder="Tu Nombre"
              />
              <p>{error.name ? error.name : ''}</p>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-12">
            <div className="form-field">
              <input
                onChange={this.changeHandler}
                value={email}
                type="email"
                name="email"
                placeholder="Tu Email"
              />
              <p>{error.email ? error.email : ''}</p>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-12">
            <div className="form-field">
              <input
                value={phone}
                onChange={this.changeHandler}
                type="text"
                name="phone"
                placeholder="Tu Teléfono"
              />
              <p>{error.phone ? error.phone : ''}</p>
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-12">
            <div className="form-field">
              <select
                className="form-control"
                onChange={this.changeHandler}
                value={subject}
                name="subject"
              >
                <option>Asunto</option>
                <option>Derecho Educacional</option>
                <option>Derecho de los Negocios</option>
                <option>Derecho Laboral</option>
                <option>Derecho Civil</option>
                <option>Otros</option>
              </select>
              <p>{error.subject ? error.subject : ''}</p>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="form-field">
              <textarea
                onChange={this.changeHandler}
                value={notes}
                name="notes"
                placeholder="Mensaje..."
              />
              <p>{error.notes ? error.notes : ''}</p>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="form-submit">
              <button type="submit" className="theme-btn">
                Enviar Ahora
              </button>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

export default ContactForm;
