import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ts1 from '../../images/testimonial/ts1.jpeg'
import ts2 from '../../images/testimonial/ts2.jpeg'
import ts3 from '../../images/testimonial/ts3.jpeg'
import ts4 from '../../images/testimonial/ts4.jpeg'
import ts5 from '../../images/testimonial/ts5.jpg'
import ts6 from '../../images/testimonial/ts6.jpeg'
import ts7 from '../../images/testimonial/ts7.jpeg'
import ts8 from '../../images/testimonial/ts8.jpeg'
import ts9 from '../../images/testimonial/ts9.jpeg'
import ts10 from '../../images/testimonial/ts10.jpeg'




class Testimonial extends Component {
    render() {
        var settings = {
            dots: true,
            arrows: false,
            speed: 1000,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
        };

        const testimonial = [
          {
            tsImg: ts1,
            Des: '“There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour variations incididunt ut labore et dolore. Quis ipsum suspendisse ultrices gravida.”',
            Title: 'Marina Fiordo Aysén',
          },
          {
            tsImg: ts2,
            Des: '“There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour variations incididunt ut labore et dolore. Quis ipsum suspendisse ultrices gravida.”',
            Title: 'Escuela Altamira de Coyhaique',
          },
          {
            tsImg: ts3,
            Des: '“There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour variations incididunt ut labore et dolore. Quis ipsum suspendisse ultrices gravida.”',
            Title: 'CD Distribution Chile SpA',
          },
          {
            tsImg: ts4,
            Des: '“There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour variations incididunt ut labore et dolore. Quis ipsum suspendisse ultrices gravida.”',
            Title: 'Colegio Union Fraterna Coyhaique',
          },
          {
            tsImg: ts5,
            Des: '“There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour variations incididunt ut labore et dolore. Quis ipsum suspendisse ultrices gravida.”',
            Title: 'Colegio Diego Portales Coyhaique',
          },
          {
            tsImg: ts6,
            Des: '“There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour variations incididunt ut labore et dolore. Quis ipsum suspendisse ultrices gravida.”',
            Title: 'Liceo San José UR Puerto Aysén',
          },
          {
            tsImg: ts7,
            Des: '“There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour variations incididunt ut labore et dolore. Quis ipsum suspendisse ultrices gravida.”',
            Title: 'Xtreme Play',
          },
          {
            tsImg: ts8,
            Des: '“There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour variations incididunt ut labore et dolore. Quis ipsum suspendisse ultrices gravida.”',
            Title: 'Constructora Deuman Austral',
          },
          {
            tsImg: ts9,
            Des: '“There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour variations incididunt ut labore et dolore. Quis ipsum suspendisse ultrices gravida.”',
            Title: 'Mega Consulting',
          },
          {
            tsImg: ts10,
            Des: '“There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour variations incididunt ut labore et dolore. Quis ipsum suspendisse ultrices gravida.”',
            Title: 'Colegio Mater Dolorosa',
          },
        ];
        return (
          <section className="wpo-testimonials-section section-padding">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-6 col-12">
                  <div className="wpo-section-title">
                    <h2>Quienes han depositado su confianza en esta firma</h2>
                    <p>
                      A lo largo de nuestra trayectoria, hemos tenido el honor
                      de colaborar con reconocidas marcas y empresas que han
                      depositado su confianza en nosotros. Algunos de ellos son:
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 col-12">
                  <div className="wpo-testimonial-wrap owl-carousel">
                    <Slider {...settings}>
                      {testimonial.map((tesmnl, tsm) => (
                        <div className="wpo-testimonial-item" key={tsm}>
                          <div className="wpo-testimonial-info">
                            <div className="wpo-testimonial-info-img">
                              <img src={tesmnl.tsImg} alt="" />
                            </div>
                            <div className="wpo-testimonial-info-text">
                              <h5>{tesmnl.Title}</h5>
                              <span>{tesmnl.Sub}</span>
                            </div>
                          </div>
                        </div>
                      ))}
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </section>
        );
    }
}

export default Testimonial;