import React, {Fragment} from 'react';
import Navbar from '../../components/Navbar'
import Hero from '../../components/hero'
import About from '../../components/about'
import Practice2 from '../../components/PracticeS2'
import Testimonial from '../../components/Testimonial'
import Consultinencey from '../../components/Consultinencey'
import BlogSection from '../../components/BlogSection'
import BoletinSection from '../../components/BoletinSection'
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
import Logo from '../../images/logo.svg'
import Slider from '../../components/extra'
import { Helmet } from 'react-helmet';

const HomePage3 =() => {
    return (
      <Fragment>
        <Helmet>
          <title>Inicio - H&A Abogados</title>
          <meta
            name="description"
            content="Estudio H&A Abogados, especialistas en derecho laboral, civil, penal, familia, administrativo, tributario, corporativo, inmobiliario, entre otros."
          />
        </Helmet>
        <Navbar
          hclass={'wpo-header-style-2'}
          Logo={Logo}
          topbarNone={'header-without-topbar'}
        />
        <Hero heroClass={'wpo-hero-slider-s2'} />
        <About />
        <Slider />
        <Practice2 />
        <Testimonial />
        <BlogSection />
        <BoletinSection />
        <Consultinencey />
        <Footer />
        <Scrollbar />
      </Fragment>
    );
};
export default HomePage3;