import atimg1 from '../images/team/team1.jpg'
import atimg2 from '../images/team/team2.jpg'


const Attorneys = [
  {
    Id: '1',
    AtImg: atimg1,
    name: 'Pablo Hernández Arias',
    name_page: 'Pablo Hernández Arias (MBL)',
    title: 'CEO de H&A Abogados',
    parrafo:
      'CEO de H&A Abogados, encargado del área del derecho comercial y societario. Responsable del asesoramiento de empresas nacionales y extranjeras tanto en su apertura, funcionamiento y defensa de sus negocios. Encargado en el asesoramiento legal de establecimientos educacionales.-Abogado de la Universidad Andrés Bello, Magíster en Derecho de los Negocios, L.L.M Business Law, Escuela de Negocios, Universidad Adolfo Ibáñez, Diplomado en Derecho a la Salud y Responsabilidad Civil Médica, Universidad Diego Portales, Curso de “Coaching Ejecutivo” de Programa de Capacitación y Desarrollo Integral, Pontificia Universidad Católica de Chile. Diplomado de Redacción Creativa en la Universidad Diego Portales. Diplomado en Lectura Crítica, de la Escuela de Artes Liberales de la Universidad Adolfo Ibáñez.',
    linkedin: 'https://www.linkedin.com/in/pablo-hern%C3%A1ndez-arias-hya/',
  },
  {
    Id: '2',
    AtImg: atimg2,
    name: 'Daniela Sepúlveda Carvajal',
    name_page: 'Daniela Sepúlveda Carvajal',
    title: 'Asesora Legal de H&A Abogados',
    parrafo:
      'Asesora Legal en H&A Abogados, encargada de la consultoría, a entidades del ámbito educacional privado y sin fines de lucro; Apoyo ante eventos adversos, de convivencia escolar y relatora de la Ley N° 21.545 (TEA); Práctica en Corporación de Asistencia Judicial de Independencia, en el área del derecho de familia.-Estudiante de Derecho de la Universidad Finis Terrae. Cursos de Filosofía Contemporánea; Pensamiento, Discurso y Argumentación. Ambos, de las Escuelas de Verano de la Universidad de Chile. Curso de Actualización de Problemas en la Responsabilidad Civil de la Universidad Adolfo Ibáñez. Cursando Diplomado en Gestión de la Convivencia Escolar en la Universidad Finis Terrae.',
    linkedin: 'https://www.linkedin.com/in/daniela-sep%C3%BAlveda-carvajal/',
  },
];

export default Attorneys;