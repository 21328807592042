import React, {Fragment} from 'react';
import Navbar from '../../components/Navbar'
import PageTitle from '../../components/pagetitle'
import Attorney from '../../components/attorneys'
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
import Logo from '../../images/logo.svg'

import { Helmet } from'react-helmet';

const AttorneysPage =() => {
    return (
      <Fragment>
        <Helmet>
          <title>Nuestro Equipo - H&A Abogados</title>
          <meta
            name="description"
            content="Conozca al equipo de abogados de H&A Abogados y su experiencia profesional."
          />
        </Helmet>
        <Navbar
          hclass={'wpo-header-style-2'}
          Logo={Logo}
          topbarNone={'header-without-topbar'}
        />
        <PageTitle pageTitle={'Nuestro Equipo'} pagesub={'Equipo'} />
        <Attorney />
        <Footer />
        <Scrollbar />
      </Fragment>
    );
};
export default AttorneysPage;
