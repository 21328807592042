import simg from '../images/service/img-2.jpg'
import simg2 from '../images/service/img-1.jpg'
import simg3 from '../images/service/img-3.jpg'
import simg4 from '../images/service/img-4.jpg'

import sSingleimg1 from '../images/service-single/2.jpg'
import sSingleimg2 from '../images/service-single/3.jpg'


import ins1 from '../images/instragram/14.jpg'
import ins2 from '../images/instragram/13.jpg'
import ins3 from '../images/instragram/15.jpg'
import ins4 from '../images/instragram/16.jpg'


const Services = [
  {
    Id: '1',
    valor: '3',
    sImg: simg,
    sTitle: 'Derecho Educacional',
    description:
      'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo ',
    des2: 'The lower-numbered purposes are better understood and practiced',
    des3: 'Elaboración de manuales de convivencia escolar y protocolos de acción; Defensa ante procedimientos administrativos de la Superintendencia de Educación; Asesoría permanente en contingencia de convivencia escolar; Asesoría en el uso y manejo de recursos por subvención general, SEP y PIE; Capacitaciones para los equipos de convivencias y consejo de profesores',
    icon: 'fi flaticon-graduation-cap',
    ssImg1: sSingleimg1,
    ssImg2: sSingleimg2,
    sinst: ins1,
  },
  {
    Id: '2',
    valor : '1',
    sImg: simg2,
    sTitle: 'Derecho Laboral Corporativo',
    description:
      'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo ',
    des2: 'The lower-numbered purposes are better understood and practiced',
    des3: 'Juicios laborales de alta complejidad; Negociaciones colectivas; Recursos administrativos ante la Inspección del Trabajo; Prevención legal en eventos adversos laborales; Redacción de contratos de trabajos, anexos de contrato, cartas de amonestación, cartas de despido; Actualización constante del Reglamento Interno de Orden Seguridad e Higiene; Elaboración de protocolos de acoso laboral.',
    icon: 'fi flaticon-auction',
    ssImg1: sSingleimg1,
    ssImg2: sSingleimg2,
    sinst: ins2,
  },
  {
    Id: '3',
    valor: '1',
    sImg: simg3,
    sTitle: 'Derecho Societario y Comercial',
    description:
      'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo ',
    des2: 'The lower-numbered purposes are better understood and practiced',
    des3: 'Constitución y modificación de sociedades; Reorganización empresarial y Due diligence; Asesoría legal en apertura y funcionamiento de negocios; Marcas y propiedad intelectual.',
    icon: 'fi flaticon-balance',
    ssImg1: sSingleimg1,
    ssImg2: sSingleimg2,
    sinst: ins3,
  },
  {
    Id: '4',
    valor: '0',
    sImg: simg4,
    sTitle: 'Derecho Civil',
    description:
      'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo ',
    des2: 'The lower-numbered purposes are better understood and practiced',
    des3: 'Materiales civiles y de familia de alta complejidad; Redacción y revisión de escrituras y contratos; Demandas de indemnización de perjuicios; Asesoría legal en proyectos inmobiliarios.',
    icon: 'fi flaticon-auction',
    ssImg1: sSingleimg1,
    ssImg2: sSingleimg2,
    sinst: ins4,
  },
];
export default Services;