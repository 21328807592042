import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar';
import PageTitle from '../../components/pagetitle';
import Scrollbar from '../../components/scrollbar';
import blogs from '../../api/blogs';
import BlogSingle from '../../components/BlogDetails/blog1';
import Footer from '../../components/footer';
import Logo from '../../images/logo.svg';


import { Helmet } from'react-helmet';

const BlogDetails = () => {

  const BlogDetails = blogs.find((item) => item.id === '1');

  return (
    <Fragment>
      <Helmet>
        <title>
          Cómo prevenir y proteger la salud mental de sus trabajadores - H&A
          Abogados
        </title>
        <meta
          name="description"
          content="Artículo sobre la protección de la salud mental en el entorno laboral."
        />
      </Helmet>
      <Navbar
        hclass={'wpo-header-style-2'}
        Logo={Logo}
        topbarNone={'header-without-topbar'}
      />
      <PageTitle pageTitle={BlogDetails.title} pagesub={'Opinión'} />
      <BlogSingle />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default BlogDetails;
