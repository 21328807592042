import React from 'react';
import ContactForm from '../ContactFrom'


const Contactpage = () => {

    return (
      <section className="wpo-contact-pg-section section-padding">
        <div className="container">
          <div className="row">
            <div className="col col-lg-10 offset-lg-1">
              <div className="office-info">
                <div className="row">
                  <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                    <div className="office-info-item">
                      <div className="office-info-icon">
                        <div className="icon">
                          <i className="fi flaticon-placeholder"></i>
                        </div>
                      </div>
                      <div className="office-info-text">
                        <h2>Dirección</h2>
                        <p style={{ textAlign: 'center' }}>
                          Calle Morandé Nº 835, Of. 601. Santiago.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                    <div className="office-info-item">
                      <div className="office-info-icon">
                        <div className="icon">
                          <i className="fi flaticon-email"></i>
                        </div>
                      </div>
                      <div className="office-info-text">
                        <h2>Email</h2>
                        <p style={{ textAlign: 'center' }}>
                          Contacto@estudiohya.cl
                        </p>
                        <p style={{ textAlign: 'center' }}>
                          Info@estudiohya.cl
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col col-xl-4 col-lg-6 col-md-6 col-12">
                    <div className="office-info-item">
                      <div className="office-info-icon">
                        <div className="icon">
                          <i className="fi flaticon-phone-call"></i>
                        </div>
                      </div>
                      <div className="office-info-text">
                        <h2>LLamanos</h2>
                        <p style={{ textAlign: 'center' }}>+56 2 32451291</p>
                        <p style={{ textAlign: 'center' }}>+56 2 32451291</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="wpo-contact-title">
                <h2>
                  <div className="wpo-section-title">
                    <h2>Necesitas Asesoría</h2>
                  </div>
                </h2>
              </div>
              <div className="wpo-contact-form-area">
                <ContactForm />
              </div>
            </div>
          </div>
        </div>
        <section className="wpo-contact-map-section">
          <div className="wpo-contact-map">
            <iframe
              title="Mapa de la Oficina H&A Abogados en Santiago"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3329.537699373386!2d-70.65166288479732!3d-33.44169668077992!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662c507d1bc1df3%3A0x925878531c899d3a!2sCalle%20Morand%C3%A9%20835%2C%20Santiago%2C%20Regi%C3%B3n%20Metropolitana%2C%20Chile!5e0!3m2!1ses!2sus!4v1690659987421!5m2!1ses!2sus"
              width="600"
              height="450"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            />
          </div>
        </section>
      </section>
    );
        
}

export default Contactpage;
