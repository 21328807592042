// images
import blogImg1 from '../images/boletin/screens/img-1.jpg';

// import blogAvaterImg1 from '../images/boletin/blog-avater/img-1.jpeg';
import blogAvaterImg2 from '../images/boletin/blog-avater/img-2.jpg';
// import blogAvaterImg3 from "../images/blog/blog-avater/img-3.jpg";

import blogSingleImg1 from '../images/boletin/boletin1.jpg';

// blClass format-standard-image o format-video

const blogs = [
  {
    id: '1',
    webid: 'ley-karin-acoso-laboral',
    equipoid: '2',
    title: 'Ley Karin - Acoso Laboral',
    screens: blogImg1,
    description:
      'La Ley Karin, viene a modificar el Código del Trabajo y otros cuerpos legales, en materia de prevención, investigación y sanción del acoso laboral, sexual o de violencia en el trabajo. En definitiva, la norma busca robustecer la legislación contra el acoso sexual, laboral y violencia en el trabajo. La ley establece que las relaciones laborales deben ser libres de violencia y respetar la dignidad de las personas, así como promover la inclusión y la igualdad de género. Se consideran conductas contrarias: el acoso sexual y laboral, también se prohíbe la violencia en el trabajo por parte de terceros ajenos a la relación laboral, como clientes o proveedores.',
    author: 'Daniela Sepúlveda Carvajal',
    authorTitle: 'Criminal Lawyer',
    authorImg: blogAvaterImg2,
    create_at: 'Enero, 2024',
    blogSingleImg: blogSingleImg1,
    comment: '< 7 min.',
    blClass: 'format-standard-image',
  },
];
export default blogs;
