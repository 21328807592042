import React from 'react';
import Services from '../../api/service';


const PracticeS2 = (props) => {



    return (
      <section className="wpo-service-section-s2 section-padding">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5">
              <div className="wpo-section-title">
                <h2 className="color">Nuestros Servicios</h2>
              </div>
            </div>
            <div className="col-lg-5 offset-lg-2">
              <div className="wpo-section-title">
                <p className="color">
                  En <strong>H&A Abogados</strong>, ofrecemos servicios
                  jurídicos, enfocados en la prevención de contingencias legales
                  y defensa de tus intereses.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            {Services.map((service, Sitem) => (
              <div className="col-lg-6 col-md-6 col-12" key={Sitem}>
                <div className="wpo-service-item">
                  <div className="wpo-service-icon">
                    <div className="icon">
                      <i className={service.icon}></i>
                    </div>
                  </div>
                  <div className="wpo-service-text">
                    <h2>{service.sTitle}</h2>
                    <ul>
                      {service.des3.split(';').map((detail, index) => (
                        <li key={index}>
                          <p>{detail.trim()}</p>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    );
}


export default PracticeS2;