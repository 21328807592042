import React from 'react';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import blogs from '../../api/blogs';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const BlogSection = (props) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplay: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section className="wpo-blog-section section-padding">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-5">
            <div className="wpo-section-title">
              <h2 className="color">Columna de Opinión</h2>
            </div>
          </div>
          <div className="col-lg-5 offset-lg-2">
            <div className="wpo-section-title">
              <p className="color">
                ¡Bienvenido al lugar donde el derecho se entrelaza con la
                cultura, el cine, la filosofía y la jurisprudencia! Descubre un
                mundo de opiniones diversas y perspectivas enriquecedoras en
                nuestras columnas.
              </p>
            </div>
          </div>
        </div>
        <Slider {...settings}>
          {blogs
            .slice()
            .reverse()
            .map((blog, Bitem) => (
              <div key={Bitem}>
                <div className="wpo-blog-item">
                  <div className="wpo-blog-img">
                    <img src={blog.screens} alt="" />
                  </div>
                  <div className="wpo-blog-content">
                    <div className="wpo-blog-content-btm">
                      <div className="wpo-blog-content-btm-left">
                        <div className="wpo-blog-content-btm-left-img">
                          <img src={blog.authorImg} alt="" />
                        </div>
                        <div className="wpo-blog-content-btm-left-text">
                          <h4 className="color">
                            <Link
                              onClick={ClickHandler}
                              to={`/equipo/${blog.equipoid}`}
                            >
                              {blog.author}
                            </Link>
                          </h4>
                        </div>
                      </div>
                    </div>
                    <div className="wpo-blog-content-top">
                      <h2 className="color">
                        <Link
                          onClick={ClickHandler}
                          to={`/opinion/${blog.webid}`}
                        >
                          {blog.title}
                        </Link>
                      </h2>
                      <Link
                        onClick={ClickHandler}
                        className="b-btn"
                        to={`/opinion/${blog.webid}`}
                      >
                        Ver más ...
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </Slider>
      </div>
    </section>
  );
};

export default BlogSection;
