import React from 'react';
import { Link } from 'react-router-dom';
import Author from '../../images/blog/blog-avater/Author1.jpg';
import blogs from '../../api/blogs';
import BlogSidebar from '../BlogSidebar';

const BlogSingle = (props) => {
  const BlogDetails = blogs.find((item) => item.id === '3');

  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <section className="wpo-blog-single-section section-padding">
      <div className="container">
        <div className="row">
          <div className={`col col-lg-8 col-12 ${props.blRight}`}>
            <div className="wpo-blog-content">
              <div className="post format-standard-image">
                <div className="entry-media">
                  <img src={BlogDetails.blogSingleImg} alt="" />
                </div>
                <div className="entry-meta">
                  <ul>
                    <li>
                      <i className="fi flaticon-user"></i> By{' '}
                      <Link to="/equipo/1">
                        <strong>{BlogDetails.author}</strong>
                      </Link>{' '}
                    </li>
                    <li>
                      <i className="fi flaticon-comment-white-oval-bubble"></i>{' '}
                      Tiempo de Lectura {BlogDetails.comment}
                    </li>
                    <li>
                      <i className="fi flaticon-calendar"></i>{' '}
                      {BlogDetails.create_at}
                    </li>
                  </ul>
                </div>
                <h2>
                  La importancia de un <span>Asesor Legal</span> en
                  establecimientos educacionales.
                </h2>
                <p>
                  Comienza el año escolar. En solo media jornada, se genera un
                  evento adverso de convivencia entre estudiantes. Se inicia un
                  proceso de negociación colectiva con el Sindicato de
                  Trabajadores del establecimiento y se realiza una
                  fiscalización de recursos por la autoridad administrativa.
                  Durante la tarde, ocurre un accidente laboral, en la que se
                  involucra a una empresa contratista, encargada de la
                  reparación y mantenimiento del Colegio. Todo en un día.
                </p>
                <p>
                  Lo anterior, puede ser una tormenta perfecta. Es necesario
                  ejecutar una serie de gestiones para no agravar estos eventos.
                  Por un lado, Inspectoría General y el Encargado de Convivencia
                  Escolar, deberán activar todos sus protocolos de acción.
                  Contabilidad tendrá que recopilar los balances y estados
                  resultados para la negociación colectiva. La administración
                  atenderá a la autoridad fiscalizadora y los sostenedores
                  deberán abordar el accidente que ocurrió en sus dependencias.
                  Todo en un día.
                </p>
                <blockquote>
                  <p>
                    Contando con un <span>Asesor legal</span>, los equipos de
                    trabajo encontrarán apoyo y los sostenedores la tranquilidad
                    para enfrentar sus contingencias. En los hechos, la relación
                    con terceros no es menor. Se deben elaborar instrumentos
                    jurídicos adecuados y dar respuesta concreta a cada
                    requerimiento de la autoridad.
                  </p>
                </blockquote>
                <p>
                  Dirección en cambio, deberá centrar toda su atención, en el
                  arribo pacifico de su comunidad. Dar inicio al año escolar,
                  saludar a los estudiantes del establecimiento, sus docentes,
                  asistentes de la educación y apoderados. Sin respiro,
                  finalmente tomará conocimiento durante la tarde, de los graves
                  hechos acontecidos. Todo en un día.
                </p>
                <p>
                  Con lo indicado, queda claro que todo equipo directivo de un
                  Colegio, deberán ejecutar sus funciones enfocados al 100%, en
                  la tramitación administrativa que crean estas contingencias,
                  dejando de lado el trabajo de aula.
                </p>
                <p>
                  Peor aún, en algunos casos, se radicará la recopilación de
                  evidencias con todo el personal administrativo y docente del
                  establecimiento. Se perderá tiempo y se pueden cometer
                  importantes errores durante toda la incidencia.
                </p>
                <p>
                  Hoy en día, el derecho laboral y el derecho educacional, se
                  mantienen con una alta carga normativa. Interpretándose,
                  constantemente por medio de ordinarios, circulares y
                  resoluciones elaboradas por la Dirección del Trabajo o la
                  Superintendencia de Educación. Mucha documentación que
                  estudiar.
                </p>
                <p>
                  Contando con un “Asesor legal”, los equipos de trabajo
                  encontrarán apoyo y los sostenedores la tranquilidad para
                  enfrentar sus contingencias. En los hechos, la relación con
                  terceros no es menor. Se deben elaborar instrumentos jurídicos
                  adecuados y dar respuesta concreta a cada requerimiento de la
                  autoridad.
                </p>
                <p>
                  Ministerio de Educación, Superintendencia de Educación,
                  Dirección del Trabajo o Servicio de Impuestos Internos, son
                  solo algunos de los actores externos que interactúan a diario
                  con los establecimientos. A nivel interno: Sindicato,
                  trabajadores, apoderados y estudiantes requerirán respuesta y
                  acciones concretas ante sus requerimientos diarios. Ahí es
                  donde la figura del “Asesor Legal” es indispensable para los
                  prestadores de servicios educativos, al permitirles descansar
                  el tratamiento de estas contingencias en profesionales
                  debidamente capacitados, que permitan a todos los miembros de
                  la comunidad educativa, centrar sus energías, en mejorar el
                  ambiente laboral de sus trabajadores y por sobre todas las
                  cosas, optimizar la calidad de la educación de sus
                  estudiantes.
                </p>
              </div>

              <div className="tag-share clearfix">
                <div className="tag">
                  <span>Tags </span>
                  <ul>
                    <li>
                      <Link>Derecho Laboral</Link>
                    </li>
                    <li>
                      <Link>Acoso Laboral</Link>
                    </li>
                    <li>
                      <Link>Obligaciones del empleador</Link>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="author-box">
                <div className="author-avatar">
                  <Link to="/equipo/1" target="_blank">
                    <img src={Author} alt="" />
                  </Link>
                </div>
                <div className="author-content">
                  <Link
                    onClick={ClickHandler}
                    to="/equipo/1"
                    className="author-name"
                  >
                    Pablo Hernández Arias
                  </Link>
                  <p>H&A Abogados</p>
                  <div className="socials">
                    <ul className="social-link">
                      <li>
                        <Link to="https://www.linkedin.com/in/pablo-hern%C3%A1ndez-arias-hya/">
                          <i className="ti-linkedin"></i>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="more-posts">
                <div className="previous-post">
                  <Link
                    onClick={ClickHandler}
                    to="/opinion/trato-escolar-ausente"
                  >
                    <span className="post-control-link">Anterior</span>
                    <span className="post-name">
                      Todos unificados en la tarea de enriquecer la educación,
                      abarcando cada condición. Siguiente
                    </span>
                  </Link>
                </div>
                <div className="next-post">
                  <Link
                    onClick={ClickHandler}
                    to="/opinion/el-due-diligence-como-elemento"
                  >
                    <span className="post-control-link">Siguiente</span>
                    <span className="post-name">
                      El “due diligence” como elemento revelador de
                      contingencias.
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <BlogSidebar blLeft={props.blLeft} />
        </div>
      </div>
    </section>
  );
};

export default BlogSingle;
