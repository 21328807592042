import React from 'react';
import { Link } from 'react-router-dom';
import Author from '../../images/blog/blog-avater/Author2.jpg';
import blogs from '../../api/blogs';
import BlogSidebar from '../BlogSidebar';

const BlogSingle = (props) => {
  const BlogDetails = blogs.find((item) => item.id === '4');

  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };


  return (
    <section className="wpo-blog-single-section section-padding">
      <div className="container">
        <div className="row">
          <div className={`col col-lg-8 col-12 ${props.blRight}`}>
            <div className="wpo-blog-content">
              <div className="post format-standard-image">
                <div className="entry-media">
                  <img src={BlogDetails.blogSingleImg} alt="" />
                </div>
                <div className="entry-meta">
                  <ul>
                    <li>
                      <i className="fi flaticon-user"></i> By{' '}
                      <Link to="/equipo/2">
                        <strong>{BlogDetails.author}</strong>
                      </Link>{' '}
                    </li>
                    <li>
                      <i className="fi flaticon-comment-white-oval-bubble"></i>{' '}
                      Tiempo de Lectura {BlogDetails.comment}
                    </li>
                    <li>
                      <i className="fi flaticon-calendar"></i>{' '}
                      {BlogDetails.create_at}
                    </li>
                  </ul>
                </div>
                <h2>
                  <span>T</span>odos unificados en la tarea de <span>E</span>
                  nriquecer la educación, <span>A</span>barcando cada condición.
                </h2>
                <p>
                  Corte Suprema, acoge recurso de protección en contra de
                  establecimiento educacional, por aplicar medida
                  desproporcionada, en contra de estudiante con necesidades
                  educativas especiales.
                </p>
                <blockquote>
                  <p>
                    Seguramente has visto alguna de estas series:{' '}
                    <span>The good doctor</span>,<span>Atypical</span>,{' '}
                    <span>Woo, una abogada extraordinaria</span>. Pues bien,
                    todas ellas tienen algo en común, sus protagonistas tienen
                    un trastorno del espectro autista <span>(TEA)</span>. Con
                    ellas, quizás, logramos acercarnos tímidamente a los
                    patrones de conducta, que acompañan dicho trastorno. Sin
                    embargo, allá afuera, lejos de las luces, guiones y
                    plataformas de streaming, la realidad sigue siendo hostil y
                    llena de vicisitudes
                  </p>
                </blockquote>
                <p>
                  Por ejemplo, “Woo, una abogada extraordinaria”, es una chica
                  con un coeficiente intelectual alto. Sobresaliente. Fue la
                  mejor de su clase en primaria y luego en la Universidad. Pues
                  bien, esa, es sólo una experiencia.{' '}
                  <strong>
                    ¿Qué ocurre afuera, con aquellos que viven con TEA, pero no
                    necesariamente, son los protagonistas de una novela o una
                    serie de televisión?
                  </strong>
                </p>
                <p>
                  Pensemos en aquellas madres, que no han podido mirarse a los
                  ojos y conectar con sus hijos. Hasta que de pronto, un día,
                  aparentemente normal, mientras toman desayuno, por primera
                  vez, se miran, y se unen a través de la mirada. Padres, que
                  durante años, no logran interactuar con sus hijos. Sin
                  embargo, un día cualquiera, al despedirse, uno de ellos,
                  escucha un “Chao, papá”. Son experiencias que para el común de
                  las personas, podrían resultar cotidianas y carentes de
                  novedad. Para ellos, un regalo.
                </p>
                <p>
                  Las personas con autismo, perciben el mundo de forma
                  diferente. Ven detalles que otros no ven. Tienen dificultades
                  para procesar los diferentes estímulos. Por eso, los lugares,
                  personas o rutinas nuevas, representan un desafío. Sostener la
                  mirada con otro, puede resultar amenazante; por eso evitan el
                  contacto visual.
                </p>
                <p>
                  Si la interacción es complicada en entornos superficiales,
                  casuales, más aún lo será, en un establecimiento educacional,
                  en donde la permanencia es alta.
                </p>
                <p>
                  El desafío es grande. La integración e inclusión, es una
                  obligación, que deben cumplir todas las comunidades
                  educativas. Sin embargo, no basta con desplegar marcos
                  normativos específicos o contratar profesionales del área que
                  resuelvan estas necesidades. Es indispensable concientizar a
                  todos sus integrantes. No sólo radicando las campañas
                  preventivas con el grupo curso, sino, con apoderados y todos
                  los estamentos de un establecimiento.
                </p>
                <p>
                  Por ejemplo, al momento de activar sus protocolos y aplicar
                  alguna medida disciplinaria, en contra de un estudiante -{' '}
                  <strong>que es diferente a sus pares</strong> - se deben
                  ponderar, las particularidades de cada niño, niña o
                  adolescente.
                </p>
                <p>
                  En ese sentido, el despliegue en las comunidades educativas,
                  debe ser adaptado y de alguna forma entregar la igualdad que
                  por derecho les corresponde. Disminuyendo las barreras de
                  aprendizaje, participación, inclusión y socialización.
                </p>
                <p>
                  Hace unos días, un apoderado, interpuso una acción de
                  protección, en contra de un establecimiento, por la expulsión
                  de su hijo, ante un episodio de agresión hacia un profesor. La
                  Corte de Apelaciones de Arica indicó lo siguiente:{' '}
                  <strong>
                    <span>
                      significa una sanción desproporcional, en que si bien como
                      establecimiento educacional, tienen el deber de mantener y
                      velar por la sana convivencia escolar, ello no puede
                      sostenerse en base al sacrificio de los derechos de un
                      miembro de la comunidad escolar del que se tiene
                      conocimiento de sus especiales requerimientos.
                    </span>
                  </strong>
                </p>
                <p>
                  Agrega además, que el menor afectado “debe lidiar a nivel
                  personal con una problemática de salud compleja, proceso del
                  que el establecimiento educacional no puede sustraerse
                  decretando su expulsión, afectando su desarrollo psíquico, y
                  consecuentemente su integridad, teniendo así un trato desigual
                  respecto de otras personas con similares necesidades
                  educativas, toda vez que precisamente el establecimiento
                  cuenta con el Proyecto de Integración Educacional para alumnos
                  con necesidades educativas especiales”. (Corte de Apelaciones
                  de Arica- Rol N° 3027-2022).
                </p>
                <p>
                  La Corte de Apelaciones resolvió,{' '}
                  <span>
                    lo que ya a estas alturas, no debiese ser discusión
                  </span>
                  . Los establecimientos, deben realizar una adecuada
                  ponderación de cada una de las problemáticas que presente el
                  estudiante per se. Esto es, realizar una mínima diferencia
                  para generar la igualdad de forma.
                </p>
                <p>
                  Por ello, resulta de gran trascendencia, la publicación de la
                  Ley de Autismo, que busca resguardar la inclusión, la atención
                  integral y la protección de los derechos de las personas con
                  TEA, en el ámbito de la salud, el social, y educacional.
                </p>
                <p>
                  En efecto, quedan ciertas interrogantes: ¿Cuál será el
                  presupuesto que el estado defina para la materia? ¿Cuáles
                  serán las medidas específicas a implementar ? ¿Será suficiente
                  para lograr emparejar la cancha y aplacar el ya ampliamente
                  manipulado concepto de “desigualdad”?.
                </p>
                <p>
                  Finalmente, quiénes viven con TEA, no pueden cambiarlo, pero
                  las comunidades educativas, y la sociedad en sus diversas
                  áreas, sí pueden modificar la forma en que se relacionan con
                  personas neuro-diversas, la forma en que los integran y cómo
                  enseñan a niños y niñas, que mañana serán adultos, a abrazar
                  nuestras diferencias.
                </p>
              </div>

              <div className="tag-share clearfix">
                <div className="tag">
                  <span>Tags </span>
                  <ul>
                    <li>
                      <Link>TEA</Link>
                    </li>
                    <li>
                      <Link>Colegios</Link>
                    </li>
                    <li>
                      <Link>Neuro Divergencias</Link>
                    </li>
                    <li>
                      <Link>Asesor legal</Link>
                    </li>
                    <li>
                      <Link>Derecho Educacional</Link>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="author-box">
                <div className="author-avatar">
                  <Link to="/equipo/2" target="_blank">
                    <img src={Author} alt="" />
                  </Link>
                </div>
                <div className="author-content">
                  <Link
                    onClick={ClickHandler}
                    to="/equipo/2"
                    className="author-name"
                  >
                    Daniela Sepúlveda Carvajal
                  </Link>
                  <p>H&A Abogados</p>
                  <div className="socials">
                    <ul className="social-link">
                      <li>
                        <Link to="https://www.linkedin.com/in/daniela-sep%C3%BAlveda-carvajal/">
                          <i className="ti-linkedin"></i>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="more-posts">
                <div className="previous-post">
                  <Link
                    onClick={ClickHandler}
                    to="/opinion/desregulacion-emocional-violencia-laboral-ley-21643"
                  >
                    <span className="post-control-link">Anterior</span>
                    <span className="post-name">
                      Desregulación emocional en establecimiento educacional y
                      la violencia en el trabajo contemplada en la Ley N°
                      21.643.
                    </span>
                  </Link>
                </div>
                <div className="next-post">
                  <Link
                    onClick={ClickHandler}
                    to="/opinion/la-importancia-de-un-asesor-legal-en-establecimientos-educacionales"
                  >
                    <span className="post-control-link">Siguiente</span>
                    <span className="post-name">
                      El “due diligence” como elemento revelador de
                      contingencias.
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <BlogSidebar blLeft={props.blLeft} />
        </div>
      </div>
    </section>
  );
};

export default BlogSingle;
