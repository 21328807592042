import React from 'react'
import {Link}  from 'react-router-dom'
import Logo from '../../images/logo-2.svg'
import Services from '../../api/service';

import ins1 from '../../images/ft-icon/Linkedin.png'


const Footer = (props) =>{

    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }

     const currentYear = new Date().getFullYear();

  return (
    <footer className="wpo-site-footer">
      <div className="wpo-upper-footer">
        <div className="container">
          <div className="row">
            <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
              <div className="widget about-widget">
                <div className="logo widget-title">
                  <img src={Logo} alt="logo" />
                </div>
                <p>
                  En H&A Abogados, nos dedicamos a ofrecer soluciones legales
                  integrales, adaptadas a las necesidades específicas de cada
                  uno de nuestros clientes.
                </p>
              </div>
            </div>
            <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
              <div className="widget link-widget">
                <div className="widget-title">
                  <h3>Nuestros Servicios </h3>
                </div>
                <ul>
                  {Services.slice(0, 5).map((service, Sitem) => (
                    <li key={Sitem}>
                      <Link
                        onClick={ClickHandler}
                        to={`/practice-single/${service.Id}`}
                      >
                        {service.sTitle}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col col-lg-4 col-md-6 col-sm-12 col-12">
              <div className="widget tag-widget">
                <div className="widget-title">
                  <h3>Tags </h3>
                </div>
                <ul>
                  <li>
                    <Link onClick={ClickHandler} to="/">
                      Derecho Educacional
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to="/">
                      Derecho Comercial
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to="/">
                      Propiedad Industrial
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to="/">
                      Derecho Laboral
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to="/">
                      Responsabilidad Civil
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to="/">
                      Comercio Exterior
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to="/">
                      Recurso de Protección
                    </Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col col-lg-2 col-md-6 col-sm-12 col-12">
              <div className="widget social-widget">
                <div className="widget-title">
                  <h3>Social Media</h3>
                </div>
                <ul>
                  <li>
                    <Link
                      onClick={ClickHandler}
                      to="https://www.linkedin.com/in/pablo-hern%C3%A1ndez-arias-hya/"
                    >
                      <i>
                        <img src={ins1} alt="" />
                      </i>{' '}
                      Pablo Hernández
                    </Link>
                  </li>
                  <li>
                    <Link
                      onClick={ClickHandler}
                      to="https://www.linkedin.com/in/daniela-sep%C3%BAlveda-carvajal/"
                    >
                      <i>
                        <img src={ins1} alt="" />
                      </i>{' '}
                      Daniela Sepúlveda
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="wpo-lower-footer">
        <div className="container">
          <div className="row">
            <div className="col col-xs-12">
              <p className="copyright">
                {' '}
                Copyright &copy; {currentYear} H&A Abogados. All Rights
                Reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
} 

export default Footer;