import React, {Fragment} from 'react';
import Navbar from '../../components/Navbar'
import PageTitle from '../../components/pagetitle'
import BlogList from '../../components/BlogList'
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
import Logo from '../../images/logo.svg'

import { Helmet } from'react-helmet';

const BlogPage =() => {
    return (
      <Fragment>
        <Helmet>
          <title>Columna de Opinión - H&A Abogados</title>
          <meta
            name="description"
            content="Lea los últimos artículos de opinión y análisis legal de H&A Abogados."
          />
        </Helmet>
        <Navbar
          hclass={'wpo-header-style-2'}
          Logo={Logo}
          topbarNone={'header-without-topbar'}
        />
        <PageTitle pageTitle={'Columna De Opinión'} pagesub={'Opinión'} />
        <BlogList />
        <Footer />
        <Scrollbar />
      </Fragment>
    );
};
export default BlogPage;

