import React, {Fragment} from 'react';
import Navbar from '../../components/Navbar'
import PageTitle from '../../components/pagetitle'
import About from '../../components/aboutfull'
import FunFact from '../../components/FunFact'
import Attorney from '../../components/attorneys'
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
import Logo from '../../images/logo.svg'

import { Helmet } from'react-helmet';

const AboutPage =() => {
    return (
      <Fragment>
        <Helmet>
          <title>Quiénes Somos - H&A Abogados</title>
          <meta
            name="description"
            content="Conozca más sobre el equipo y la historia de H&A Abogados."
          />
        </Helmet>
        <Navbar
          hclass={'wpo-header-style-2'}
          Logo={Logo}
          topbarNone={'header-without-topbar'}
        />
        <PageTitle pageTitle={'Acerca De Nosotros'} pagesub={'Quiénes Somos'} />
        <About />
        <FunFact />
        <Attorney />
        <Footer />
        <Scrollbar />
      </Fragment>
    );
};
export default AboutPage;
