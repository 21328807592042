import React, { Component } from 'react';
import emailjs from '@emailjs/browser';
import Swal from 'sweetalert2';

class Consultinencey extends Component {
  state = {
    name: '',
    email: '',
    subject: '',
    phone: '',
    notes: '',
    error: {},
  };

  componentDidMount() {
    emailjs.init('X9RXJbAaYa_9JRWWY');
  }

  changeHandler = (e) => {
    const error = this.state.error;
    error[e.target.name] = '';

    this.setState({
      [e.target.name]: e.target.value,
      error,
    });
  };

  submitHandler = (e) => {
    e.preventDefault();

    const { name, email, subject, phone, notes, error } = this.state;

    if (name === '') error.name = 'Please enter your name';
    if (email === '') error.email = 'Please enter your email';
    if (subject === '') error.subject = 'Please enter your subject';
    if (phone === '') error.phone = 'Please enter your phone number';
    if (notes === '') error.notes = 'Please enter your note';

    if (Object.values(error).some((err) => err !== '')) {
      this.setState({ error });
    } else {
      // Enviar email utilizando EmailJS
      const templateParams = {
        from_name: name,
        reply_to: email,
        subject: subject,
        message: notes,
        phone: phone,
      };

      emailjs.send('EstudioHyA', 'template_estudiohya', templateParams).then(
        () => {
          // Notificación de éxito
          Swal.fire({
            icon: 'success',
            title: '¡Mensaje Enviado!',
            text: 'Tu mensaje ha sido enviado exitosamente. Nos pondremos en contacto contigo pronto.',
            confirmButtonText: 'Cerrar',
          });

          // Restablecer el estado del formulario
          this.setState({
            name: '',
            email: '',
            subject: '',
            phone: '',
            notes: '', // Limpia el campo notes después de enviar el mensaje
            error: {},
          });
        },
        (error) => {
          // Notificación de error
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Hubo un problema al enviar tu mensaje. Por favor, intenta de nuevo.',
            confirmButtonText: 'Cerrar',
          });

          console.log('Failed to send email...', error);
        },
      );
    }
  };

  render() {
    const { name, email, subject, phone, notes, error } = this.state;

    return (
      <section className="wpo-consultancy-section section-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-12">
              <div className="wpo-consultancy-form-area">
                <div className="wpo-section-title">
                  <h2>Necesitas Asesoría</h2>
                  <p>
                    ¡Estamos a un clic de distancia! ¡Esperamos con ansias
                    conocer tu historia y poder ayudarte en lo que necesites! 😊
                  </p>
                </div>
                <form onSubmit={this.submitHandler} className="form">
                  <div className="row justify-content-center">
                    <div className="col-lg-6 col-12">
                      <div className="form-field">
                        <input
                          className="form-control"
                          value={name}
                          onChange={this.changeHandler}
                          type="text"
                          name="name"
                          placeholder="Tu Nombre"
                        />
                        <p>{error.name}</p>
                      </div>
                    </div>
                    <div className="col-lg-6 col-12">
                      <div className="form-field">
                        <input
                          className="form-control"
                          onChange={this.changeHandler}
                          value={email}
                          type="email"
                          name="email"
                          placeholder="Tu Email"
                        />
                        <p>{error.email}</p>
                      </div>
                    </div>
                    <div className="col-lg-6 col-12">
                      <div className="form-field">
                        <input
                          className="form-control"
                          onChange={this.changeHandler}
                          value={phone}
                          type="phone"
                          name="phone"
                          placeholder="Tu Teléfono"
                        />
                        <p>{error.phone}</p>
                      </div>
                    </div>
                    <div className="col-lg-6 col-12">
                      <div className="form-field">
                        <select
                          className="form-control"
                          onChange={this.changeHandler}
                          value={subject}
                          name="subject"
                        >
                          <option>Asunto</option>
                          <option>Derecho Educacional</option>
                          <option>Derecho de los Negocios</option>
                          <option>Derecho Laboral</option>
                          <option>Derecho Civil</option>
                          <option>Otros</option>
                        </select>
                        <p>{error.subject}</p>
                      </div>
                    </div>
                    <div className="fullwidth">
                      <textarea
                        className="form-control"
                        onChange={this.changeHandler}
                        value={notes}
                        name="notes"
                        placeholder="Mensaje..."
                      />
                    </div>
                    <div className="col-lg-12">
                      <div className="form-submit">
                        <button type="submit" className="theme-btn">
                          Enviar Ahora
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
                <div className="border-style"></div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Consultinencey;
