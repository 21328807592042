import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar'
import PageTitle from '../../components/pagetitle'
import Scrollbar from '../../components/scrollbar'
import { useParams } from 'react-router-dom'
import Attorneys from '../../api/attorneys';
import Footer from '../../components/footer'
import Logo from '../../images/logo.svg'


import { Helmet } from 'react-helmet';

const AttorneySinglePage = (props) => {
    const { id } = useParams()

    const AttorneysDetails = Attorneys.find(item => item.Id === id)




    return (
      <Fragment>
        <Helmet>
          <title>Perfil de Abogado - H&A Abogados</title>
          <meta
            name="description"
            content="Detalles sobre el perfil de nuestros abogados en H&A Abogados."
          />
        </Helmet>
        <Navbar
          hclass={'wpo-header-style-2'}
          Logo={Logo}
          topbarNone={'header-without-topbar'}
        />
        <PageTitle
          pageTitle={AttorneysDetails.name}
          pagesub={AttorneysDetails.title}
        />
        <div className="wpo-about-section section-padding">
          <div className="container">
            <div className="wpo-about-section-wrapper">
              <div className="row align-items-center">
                <div className="col-lg-5 col-md-12 col-12">
                  <div className="wpo-about-img-wrapper">
                    <div className="wpo-about-img">
                      <img src={AttorneysDetails.AtImg} alt="about1" />
                    </div>
                  </div>
                </div>
                <div className="col-lg-7 col-md-12 col-12">
                  <div className="wpo-about-content">
                    <div className="wpo-section-title">
                      <h2>{AttorneysDetails.name_page}</h2>
                    </div>
                    <div className="wpo-about-content-inner">
                      {AttorneysDetails.parrafo
                        .split('-')
                        .map((text, index) => (
                          <p key={index}>{text.trim()}</p>
                        ))}
                      <div className="signeture">
                        <h4>{AttorneysDetails.name_page}</h4>
                        <p>{AttorneysDetails.title}</p>
                        {/* <span>
                        <img src={sign} alt="" />
                      </span> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
        <Scrollbar />
      </Fragment>
    );
};
export default AttorneySinglePage;
