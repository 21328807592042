import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import hero1 from '../../images/slider/slide-1.jpg'
import hero2 from '../../images/slider/slide-2.jpg'



class Hero extends Component {
    render() {

        var settings = {
            dots: false,
            arrows: true,
            speed: 1200,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 2500,
            fade: true
        };

        return (
          <section className={`wpo-hero-slider ${this.props.heroClass}`}>
            <div className="hero-container">
              <div className="hero-wrapper">
                <Slider {...settings}>
                  <div className="hero-slide">
                    <div
                      className="slide-inner slide-bg-image"
                      style={{ backgroundImage: `url(${hero1})` }}
                    >
                      <div className="container-fluid">
                        <div className="slide-content">
                          <div className="slide-title">
                            <h2>H & A Abogados</h2>
                          </div>
                          <div className="slide-text">
                            <p>
                              Te ayudamos. Te acompañamos. Te representamos; en
                              todas las etapas y contingencias legales, en la
                              apertura y funcionamiento de tu negocio.
                            </p>
                          </div>
                          <div className="clearfix"></div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="hero-slide">
                    <div
                      className="slide-inner slide-bg-image"
                      style={{ backgroundImage: `url(${hero2})` }}
                    >
                      <div className="container-fluid">
                        <div className="slide-content">
                          <div className="slide-title">
                            <h2>H & A Abogados</h2>
                          </div>
                          <div className="slide-text">
                            <p>
                              Te ayudamos. Te acompañamos. Te representamos; en
                              todas las etapas y contingencias legales, en la
                              apertura y funcionamiento de tu negocio.
                            </p>
                          </div>
                          <div className="clearfix"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
          </section>
        );
    }
}

export default Hero;