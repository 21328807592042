import React from 'react';
import { Link } from 'react-router-dom';
import Author from '../../images/blog/blog-avater/Author1.jpg';
import blogs from '../../api/blogs';
import BlogSidebar from '../BlogSidebar';

const BlogSingle = (props) => {
  const BlogDetails = blogs.find((item) => item.id === '5');

  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <section className="wpo-blog-single-section section-padding">
      <div className="container">
        <div className="row">
          <div className={`col col-lg-8 col-12 ${props.blRight}`}>
            <div className="wpo-blog-content">
              <div className="post format-standard-image">
                <div className="entry-media">
                  <img src={BlogDetails.blogSingleImg} alt="" />
                </div>
                <div className="entry-meta">
                  <ul>
                    <li>
                      <i className="fi flaticon-user"></i> By{' '}
                      <Link to="/equipo/1">
                        <strong>{BlogDetails.author}</strong>
                      </Link>{' '}
                    </li>
                    <li>
                      <i className="fi flaticon-comment-white-oval-bubble"></i>{' '}
                      Tiempo de Lectura {BlogDetails.comment}
                    </li>
                    <li>
                      <i className="fi flaticon-calendar"></i>{' '}
                      {BlogDetails.create_at}
                    </li>
                  </ul>
                </div>
                <h2>
                  Desregulación emocional en establecimiento educacional y la
                  violencia en el trabajo contemplada en la Ley N° 21.643.
                </h2>
                <p>
                  En los últimos 4 años, las agresiones de estudiantes hacia
                  docentes y asistentes de la educación, han sido parte de la
                  línea editorial de muchos medios de comunicación. Sin embargo,
                  al observar estadísticas de la Superintendencia del ramo, este
                  tipo de agresiones, no han sido lo suficientemente
                  consideradas, en la investigación educacional. Lo expuesto,
                  genera una preocupación evidente, especialmente en el contexto
                  de la nueva Ley N° 21.643, que aborda la prevención,
                  investigación y sanción del acoso laboral, el acoso sexual y
                  la violencia en el trabajo. Sin datos, no le damos fuerza al
                  relato.
                </p>
                <p>
                  De los riesgos laborales mencionados - por la Ley Karin - es
                  la violencia en el trabajo, la más interesante incorporación
                  de la norma. Aun así, este concepto, puede entrar en
                  conflicto, con situaciones adversas, derivadas de la
                  desregulación emocional, en la que un niño, niña o
                  adolescente, se encuentra incapacitado para gestionar
                  adecuadamente sus emociones, produciendo, sin intencionalidad,
                  daños a terceros.
                </p>
                <p>
                  En este contexto, de acuerdo al artículo 1, letra c, de la Ley
                  N° 21.643, se considerará violencia en el trabajo “aquellas
                  conductas que afectan a las personas trabajadoras en el
                  desempeño de sus funciones, ya sea por parte de clientes,
                  proveedores o usuarios, entre otros” En este sentido, es
                  importante reconocer que los trabajadores del área de la
                  educación, durante su jornada, pueden enfrentar situaciones
                  adversas, imposibles de prever. Una de ellas, son las
                  desregulaciones emocionales de los estudiantes. Lo anterior,
                  exponiéndose a agresiones físicas y psicológicas, durante un
                  procedimiento de contención, tendiente a evitar riesgos, en la
                  integridad del niño o niñas desregulado y otros miembros de la
                  comunidad educativa.
                </p>
                <p>
                  Recientemente, la Superintendencia de Educación emitió un
                  pronunciamiento relevante a través del Ordinario N°1189,
                  señalando que los trabajadores de estás entidades, gozan de
                  derechos laborales, que son equivalentes a los de cualquier
                  otro trabajador, así como de derechos específicos, como
                  miembros de la comunidad. Con ello, se aclara, que no es
                  incompatible, activar simultáneamente los protocolos internos
                  del ámbito educativo y los procedimientos de investigación y
                  sanción relacionados con actos de violencia en el trabajo, ya
                  que estos, tienen objetivos distintos, pero no conflictivos.
                </p>
                <p>
                  Sin embargo, la principal problemática, nace con las
                  desregulaciones emocionales, ya que, por su naturaleza, son
                  agresiones diferentes, en las que no existe una
                  intencionalidad de producir un daño. Así, las acciones pueden
                  traducirse, únicamente, en una medida disciplinaria para el
                  estudiante (cuando estos no tienen un diagnóstico de base) y
                  con el trabajador asistiendo a la mutualidad respectiva para
                  su atención. Otra opción, es lograr acuerdos en un
                  procedimiento de mediación ante la autoridad administrativa.
                  Lamentablemente, en algunos casos, dichos procedimientos,
                  recaen en una manifestación de buenas intenciones, sin un real
                  compromiso, de asegurar la protección del trabajador agredido.
                </p>
                <p>
                  Destacar que la Ley Karin, enfrentará mayores dificultades al
                  ser ponderada con la Ley N° 21.545, que protege los derechos
                  de las personas con trastornos del espectro autista, debido al
                  aumento de intensidad de la desregulación e imprevisibilidad
                  del hecho. Desafortunadamente, las respuestas de la autoridad
                  educativa son genéricas. La Superintendencia de Educación, por
                  medio de la Circular N°586, a propósito del deber establecido
                  en el artículo 18 de la Ley TEA, reitera la obligación de las
                  comunidades, a ajustar su normativa interna, para el abordaje
                  de desregulaciones emocionales y conductuales. Dando
                  orientaciones, pero trasladando - en virtud de la autonomía de
                  los establecimientos - la problemática, directamente a los
                  sostenedores.
                </p>
                <p>
                  Igual de desalentador, resultó el Ordinario N°222, donde se
                  valora el esfuerzo, por dar directrices sobre el marco
                  regulatorio, para aplicar técnicas de contención física ante
                  las desregulaciones, pero señalando en su etapa de conclusión
                  que “Su realización carece de regulación legal expresa,
                  escapando a las competencias de este servicio un
                  pronunciamiento al respecto” Con ello, se reconoce
                  tácitamente, la necesidad de establecer una regulación legal
                  expresa, sobre esta materia, que hoy no existe.
                </p>
                <blockquote>
                  <p>
                    Creemos que la Ley Karin, tiene el potencial de fortalecer
                    los derechos de los trabajadores de la educación a través de
                    sus protocolos de prevención, que delineara los riesgos
                    asociados a las desregulaciones emocionales, como factor de
                    riesgo laboral. Es fundamental comprender que el principal
                    deber de estos profesionales, es proteger el interés
                    superior de los niños, niñas y adolescentes, dado su papel
                    de garantes. Además, deberán contar con la protección de sus
                    sostenedores, conforme al artículo 184 del Código del
                    Trabajo, pero faltando, en está ecuación un actor principal.
                  </p>
                </blockquote>
                <p>
                  Con lo señalado, resulta esencial socializar con padres y
                  apoderados, estos cambios normativos y resaltar la importancia
                  de considerar los derechos, de todas las partes involucradas
                  en este proceso. Sin duda, el desafío es significativo, pero
                  es un paso necesario hacia un entorno educativo más justo y
                  seguro.
                </p>
              </div>

              <div className="tag-share clearfix">
                <div className="tag">
                  <span>Tags </span>
                  <ul>
                    <li>
                      <Link>Desregulación Emocional</Link>
                    </li>
                    <li>
                      <Link>Violencia Laboral</Link>
                    </li>
                    <li>
                      <Link>Ley N° 21.643</Link>
                    </li>
                    <li>
                      <Link>Derecho Educacional</Link>
                    </li>
                    <li>
                      <Link>Ley Karin</Link>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="author-box">
                <div className="author-avatar">
                  <Link to="/equipo/1" target="_blank">
                    <img src={Author} alt="" />
                  </Link>
                </div>
                <div className="author-content">
                  <Link
                    onClick={ClickHandler}
                    to="/equipo/1"
                    className="author-name"
                  >
                    Pablo Hernández Arias
                  </Link>
                  <p>H&A Abogados</p>
                  <div className="socials">
                    <ul className="social-link">
                      <li>
                        <Link to="https://www.linkedin.com/in/pablo-hern%C3%A1ndez-arias-hya/">
                          <i className="ti-linkedin"></i>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="more-posts">
                <div className="previous-post">
                  <Link
                    onClick={ClickHandler}
                    to="/opinion/como-prevenir-y-proteger-la-salud-mental-de-sus-trabajadores"
                  >
                    <span className="post-control-link">Anterior</span>
                    <span className="post-name">
                      ¿Cómo prevenir y proteger la salud mental de sus
                      trabajadores en los fuegos de una cocina?
                    </span>
                  </Link>
                </div>
                <div className="next-post">
                  <Link
                    onClick={ClickHandler}
                    to="/opinion/trato-escolar-ausente"
                  >
                    <span className="post-control-link">Siguiente</span>
                    <span className="post-name">
                      Todos unificados en la tarea de enriquecer la educación,
                      abarcando cada condición.
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <BlogSidebar blLeft={props.blLeft} />
        </div>
      </div>
    </section>
  );
};

export default BlogSingle;
