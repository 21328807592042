import React from 'react'
import {Link} from 'react-router-dom'
import erimg from '../../images/error-404.png'


const Error = (props) => {
    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }

    return (
      <section className="error-404-section section-padding">
        <div className="container">
          <div className="row">
            <div className="col col-xs-12">
              <div className="content clearfix">
                <div className="error">
                  <img src={erimg} alt="" />
                </div>
                <div className="error-message">
                  <h3>¡Ups! ¡Página no encontrada!</h3>
                  <p>
                    Lo sentimos, pero no podemos encontrar la página que
                    solicitaste. Esto podría ser porque has escrito mal la
                    dirección web.
                  </p>
                  <Link onClick={ClickHandler} to="/" className="theme-btn">
                    {' '}
                    Volver al inicio
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
}

export default Error;