import React, {Fragment} from 'react';
import Navbar from '../../components/Navbar'
import PageTitle from '../../components/pagetitle'
import PracticeS2 from '../../components/PracticeS2'
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
import Logo from '../../images/logo.svg'

import { Helmet } from'react-helmet';

const PracticePageS2 =() => {
    return (
      <Fragment>
        <Helmet>
          <title>Nuestros Servicios - H&A Abogados</title>
          <meta
            name="description"
            content="Conozca los servicios legales que ofrece H&A Abogados en diversas áreas del derecho."
          />
        </Helmet>
        <Navbar
          hclass={'wpo-header-style-2'}
          Logo={Logo}
          topbarNone={'header-without-topbar'}
        />
        <PageTitle pageTitle={'Nuestros Servicios'} pagesub={'Servicios'} />
        <PracticeS2 />
        <Footer />
        <Scrollbar />
      </Fragment>
    );
};
export default PracticePageS2;

