import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar';
import PageTitle from '../../components/pagetitle';
import Scrollbar from '../../components/scrollbar';
import blogs from '../../api/blogs';
import BlogSingle from '../../components/BlogDetails/blog3';
import Footer from '../../components/footer';
import Logo from '../../images/logo.svg';


import { Helmet } from'react-helmet';
const BlogDetails = () => {
  const BlogDetails = blogs.find((item) => item.id === '3');

  return (
    <Fragment>
      <Helmet>
        <title>
          La importancia de un asesor legal en establecimientos educacionales -
          H&A Abogados
        </title>
        <meta
          name="description"
          content="Discusión sobre la importancia de contar con asesoría legal en el ámbito educativo."
        />
      </Helmet>
      <Navbar
        hclass={'wpo-header-style-2'}
        Logo={Logo}
        topbarNone={'header-without-topbar'}
      />
      <PageTitle pageTitle={BlogDetails.title} pagesub={'Opinión'} />
      <BlogSingle />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default BlogDetails;
