import { Route, Routes, BrowserRouter } from 'react-router-dom';
import ErrorPage from './page/ErrorPage';
import Homepage from './page/HomePage3';
import AboutPage from './page/AboutPage';
import AttorneysPage from './page/AttorneysPage';
import AttorneySinglePage from './page/AttorneySinglePage';
import PracticePageS2 from './page/PracticePageS2';
import ContactPage from './page/ContactPage';
import BlogPage from './page/BlogPage';
import BlogDetails1 from './page/Blog/Blog_1';
import BlogDetails2 from './page/Blog/Blog_2';
import BlogDetails3 from './page/Blog/Blog_3';
import BlogDetails4 from './page/Blog/Blog_4';
import BlogDetails5 from './page/Blog/Blog_5';
import BoletinPage from './page/BoletinPage';
import BoletinDetails1 from './page/Boletin/Boletin_1';

function Routing() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="*" element={<ErrorPage />} />
          <Route path={'/'} element={<Homepage />} />
          <Route path={'/quienes-somos'} element={<AboutPage />} />
          <Route path="/equipo" element={<AttorneysPage />} />
          <Route path="/equipo/:id" element={<AttorneySinglePage />} />
          <Route path="/servicios" element={<PracticePageS2 />} />
          <Route path="/contacto" element={<ContactPage />} />
          <Route path="/opinion" element={<BlogPage />} />
          <Route
            path="/opinion/como-prevenir-y-proteger-la-salud-mental-de-sus-trabajadores"
            element={<BlogDetails1 />}
          />
          <Route
            path="/opinion/el-due-diligence-como-elemento"
            element={<BlogDetails2 />}
          />
          <Route
            path="/opinion/la-importancia-de-un-asesor-legal-en-establecimientos-educacionales"
            element={<BlogDetails3 />}
          />
          <Route
            path="/opinion/trato-escolar-ausente"
            element={<BlogDetails4 />}
          />
          <Route
            path="/opinion/desregulacion-emocional-violencia-laboral-ley-21643"
            element={<BlogDetails5 />}
          />
          <Route path="/boletin" element={<BoletinPage />} />
          <Route
            path="/boletin/ley-karin-acoso-laboral"
            element={<BoletinDetails1 />}
          />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default Routing;
