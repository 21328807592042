import React from 'react';
import boletin from '../../api/boletin';
// import Slider from 'react-slick';
import { Link } from 'react-router-dom';

const boletinSection = (props) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  // const settings = {
  //   dots: true,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 3,
  //   slidesToScroll: 3,
  //   autoplay: false,
  //   responsive: [
  //     {
  //       breakpoint: 1024,
  //       settings: {
  //         slidesToShow: 2,
  //         slidesToScroll: 2,
  //       },
  //     },
  //     {
  //       breakpoint: 768,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //       },
  //     },
  //   ],
  // };

  return (
    <section className="wpo-blog-section2 section-padding">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-5">
            <div className="wpo-section-title">
              <h2>Boletines Informativos</h2>
            </div>
          </div>
          <div className="col-lg-5 offset-lg-2">
            <div className="wpo-section-title">
              <p>
                ¡Mantente al tanto de las últimas novedades legales! Explora
                nuestros boletines informativos, para entender en detalle las
                actualizaciones jurídicas y su impacto en tu día a día.
              </p>
            </div>
          </div>
        </div>
        <div className="wpo-blog-items">
          <div className="row">
            {boletin
              .slice()
              .reverse()
              .map((blog, Bitem) => (
                <div className="col col-lg-4 col-md-6 col-12" key={Bitem}>
                  <div className="wpo-blog-item">
                    <div className="wpo-blog-img">
                      <img src={blog.screens} alt="" />
                    </div>
                    <div className="wpo-blog-content">
                      <div className="wpo-blog-content-btm">
                        <div className="wpo-blog-content-btm-left">
                          <div className="wpo-blog-content-btm-left-img">
                            <img src={blog.authorImg} alt="" />
                          </div>
                          <div className="wpo-blog-content-btm-left-text">
                            <h4 className="color">
                              <Link
                                onClick={ClickHandler}
                                to={`/equipo/${blog.equipoid}`}
                              >
                                {blog.author}
                              </Link>
                            </h4>
                          </div>
                        </div>
                      </div>
                      <div className="wpo-blog-content-top">
                        <h2 className="color">
                          <Link
                            onClick={ClickHandler}
                            to={`/boletin/${blog.webid}`}
                          >
                            {blog.title}
                          </Link>
                        </h2>
                        <Link
                          onClick={ClickHandler}
                          className="b-btn"
                          to={`/boletin/${blog.webid}`}
                        >
                          Ver más ...
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default boletinSection;
