import React, {Fragment} from 'react';
import Navbar from '../../components/Navbar'
import PageTitle from '../../components/pagetitle'
import BlogList from '../../components/BoletinList'
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
import Logo from '../../images/logo.svg'


import { Helmet } from'react-helmet';

const BlogPage =() => {
    return (
      <Fragment>
        <Helmet>
          <title>Boletines - H&A Abogados</title>
          <meta
            name="description"
            content="Explora los boletines informativos de H&A Abogados sobre temas legales."
          />
        </Helmet>
        <Navbar
          hclass={'wpo-header-style-2'}
          Logo={Logo}
          topbarNone={'header-without-topbar'}
        />
        <PageTitle pageTitle={'Boletines Informativos'} pagesub={'Boletin'} />
        <BlogList />
        <Footer />
        <Scrollbar />
      </Fragment>
    );
};
export default BlogPage;

