import React from 'react'


const FunFact = (props) => {

    const funfact = [
      {
        title: '190',
        subTitle: 'Clientes Asesorados',
      },
      {
        title: '+250',
        subTitle: 'Juicios',
      },
      {
        title: 'Gestiones en 8 países',
        subTitle: 'Alcance Internacional',
      },
      {
        title: 'Presencia en',
        subTitle: 'Región Metropolitana y de Aysén',
      },
    ];

    return (
      <section className="wpo-fun-fact-section section-padding">
        <div className="container">
          <div className="row">
            <div className="col col-xs-12">
              <div className="wpo-fun-fact-grids clearfix">
                {funfact.map((funfact, fitem) => (
                  <div className="grid" key={fitem}>
                    <div className="info">
                      <h3>{funfact.title}</h3>
                      <p className="Center" style={{ textAlign: 'center' }}>
                        {funfact.subTitle}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
}

export default FunFact;