import React from 'react';
import {Link} from 'react-router-dom'
import Services from '../../api/service';


const BlogSidebar = (props) => {


    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }

    return (
      <div className={`col col-lg-4 col-12 ${props.blLeft}`}>
        <div className="blog-sidebar">
          {/* <div className="widget search-widget">
                    <form onSubmit={SubmitHandler}>
                        <div>
                            <input type="text" className="form-control" placeholder="Search Post.."/>
                            <button type="submit"><i className="ti-search"></i></button>
                        </div>
                    </form>
                </div> */}
          <div className="widget category-widget">
            <h3>Categorias</h3>
            <ul>
              {Services.map((service, Sitem) => (
                <li key={Sitem}>
                  <Link>
                    {service.sTitle} <span>{service.valor}</span>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="widget tag-widget">
            <h3>Tags</h3>
            <ul>
              <li>
                <Link>Derecho Educacional</Link>
              </li>
              <li>
                <Link>Asesoría Jurídica para Colegios</Link>
              </li>
              <li>
                <Link>Convivencia Escolar</Link>
              </li>
              <li>
                <Link>Derecho Laboral</Link>
              </li>
              <li>
                <Link>Due diligence empresarial</Link>
              </li>
            </ul>
          </div>
          <div className="wpo-contact-widget widget">
            <h2>¿Cómo Podemos Ayudarte?</h2>
            <p>
              Nos especializamos en ofrecer soluciones personalizadas y
              efectivas para tus necesidades.
            </p>
            <Link onClick={ClickHandler} to="/contacto">
              Contáctanos
            </Link>
          </div>
        </div>
      </div>
    );
        
}

export default BlogSidebar;
