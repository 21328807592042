import React, { Component } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import hero1 from '../../images/custom-slider/slide1.jpg';
import hero2 from '../../images/custom-slider/slide2.jpg';
import hero3 from '../../images/custom-slider/slide3.jpg';
import hero4 from '../../images/custom-slider/slide4.jpg';

class Hero extends Component {
  render() {
    var settings = {
      dots: false,
      arrows: true,
      speed: 1200,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2500,
      fade: true,
    };

    return (
      <section className={`wmo-hero-slider`}>
        <div className="hero-container">
          <div className="hero-wrapper">
            <Slider {...settings}>
              <div className="hero-slide">
                <div
                  className="slide-inner slide-bg-image"
                  style={{ backgroundImage: `url(${hero1})` }}
                ></div>
              </div>
              <div className="hero-slide">
                <div
                  className="slide-inner slide-bg-image"
                  style={{ backgroundImage: `url(${hero2})` }}
                ></div>
              </div>
              <div className="hero-slide">
                <div
                  className="slide-inner slide-bg-image"
                  style={{ backgroundImage: `url(${hero3})` }}
                ></div>
              </div>
              <div className="hero-slide">
                <div
                  className="slide-inner slide-bg-image"
                  style={{ backgroundImage: `url(${hero4})` }}
                ></div>
              </div>
            </Slider>
          </div>
        </div>
      </section>
    );
  }
}

export default Hero;
