// images
import blogImg1 from "../images/blog/screens/img-1.jpg";
import blogImg2 from '../images/blog/screens/img-2.jpg';
import blogImg3 from '../images/blog/screens/img-3.jpg';
import blogImg4 from '../images/blog/screens/img-4.jpg';
import blogImg5 from '../images/blog/screens/img-5.jpg';

import blogAvaterImg1 from "../images/blog/blog-avater/img-1.jpeg";
import blogAvaterImg2 from "../images/blog/blog-avater/img-2.jpg";
// import blogAvaterImg3 from "../images/blog/blog-avater/img-3.jpg";

import blogSingleImg1 from "../images/blog/blog1.jpg";
import blogSingleImg2 from '../images/blog/blog2.jpg';
import blogSingleImg3 from '../images/blog/blog3.jpg';
import blogSingleImg4 from '../images/blog/blog4.jpg';
import blogSingleImg5 from '../images/blog/blog5.jpg';

// blClass format-standard-image o format-video

const blogs = [
  {
    id: '1',
    webid: 'como-prevenir-y-proteger-la-salud-mental-de-sus-trabajadores',
    equipoid: '1',
    title:
      '¿Cómo prevenir y proteger la salud mental de sus trabajadores en los fuegos de una cocina?',
    screens: blogImg1,
    description:
      'En el competitivo ámbito gastronómico, la presión intensa demanda la implementación de estrategias para proteger la salud mental y física de los trabajadores. Es vital adoptar medidas preventivas contra el maltrato verbal y el acoso, promoviendo un ambiente de trabajo seguro mediante capacitaciones y protocolos específicos. Estas prácticas no solo fomentan un clima laboral positivo sino que también preparan a las empresas para responder adecuadamente ante posibles reclamaciones legales, transformando las cocinas en espacios creativos y respetuosos.',
    author: 'Pablo Hernández Arias',
    authorTitle: 'Criminal Lawyer',
    authorImg: blogAvaterImg1,
    create_at: 'Diciembre, 2020',
    blogSingleImg: blogSingleImg1,
    comment: '< 6 min.',
    blClass: 'format-standard-image',
  },
  {
    id: '2',
    webid: 'el-due-diligence-como-elemento',
    equipoid: '1',
    title: 'El “due diligence” como elemento revelador de contingencias.',
    screens: blogImg2,
    description:
      'En un establecimiento educativo, la confluencia de eventos adversos en un solo día destaca la importancia crítica de contar con un asesor legal. Este profesional brinda apoyo esencial, permitiendo al personal enfocarse en la educación y el bienestar de la comunidad, mientras navega por la complejidad de las normativas educativas y laborales.',
    author: 'Pablo Hernández Arias',
    authorTitle: 'Family Lawyer',
    authorImg: blogAvaterImg1,
    create_at: 'Enero, 2021',
    blogSingleImg: blogSingleImg2,
    comment: '< 5 min.',
    blClass: 'format-standard-image',
  },
  {
    id: '3',
    webid:
      'la-importancia-de-un-asesor-legal-en-establecimientos-educacionales',
    equipoid: '1',
    title:
      'La importancia de un “Asesor Legal” en establecimientos educacionales.',
    screens: blogImg3,
    description:
      'Ante el entusiasmo de un empresario por importar café Kona y establecerse rápidamente en el mercado chileno, el proceso de "Due Diligence" emerge como una herramienta crítica. Este análisis multidisciplinario abarca aspectos comerciales, financieros, legales y de planificación tributaria, asegurando una inversión informada y minimizando riesgos. Al examinar desde la viabilidad de la marca hasta la integridad del inmueble comercial deseado, el "Due Diligence" prepara al empresario para tomar decisiones estratégicas, clarificando el panorama y ajustando expectativas para una incursión empresarial segura y consciente.',
    author: 'Pablo Hernández Arias',
    authorTitle: 'Business Lawyer',
    authorImg: blogAvaterImg1,
    create_at: 'Marzo, 2021',
    blogSingleImg: blogSingleImg3,
    comment: '4 min.',
    blClass: 'format-standard-image',
  },
  {
    id: '4',
    webid: 'trato-escolar-ausente',
    equipoid: '2',
    title:
      'Todos unificados en la tarea de enriquecer la educación, abarcando cada condición.',
    screens: blogImg4,
    description:
      'La resolución de la Corte Suprema contra un colegio por sancionar desproporcionadamente a un estudiante con necesidades especiales subraya la urgencia de adaptar medidas educativas inclusivas. Destaca la necesidad de políticas que promuevan la integración efectiva y la concienciación sobre la neurodiversidad, alineándose con leyes como la de Autismo para proteger los derechos y fomentar la igualdad en el ámbito educativo.',
    author: 'Daniela Sepúlveda Carvajal',
    authorTitle: 'Family Lawyer',
    authorImg: blogAvaterImg2,
    create_at: 'Marzo, 2023',
    blogSingleImg: blogSingleImg4,
    comment: '< 7 min.',
    blClass: 'format-standard-image',
  },
  {
    id: '5',
    webid: 'desregulacion-emocional-violencia-laboral-ley-21643',
    equipoid: '1',
    title:
      'Desregulación emocional en establecimiento educacional y la violencia en el trabajo contemplada en la Ley N° 21.643.',
    screens: blogImg5,
    description:
      'En los últimos años, las agresiones de estudiantes hacia docentes han ganado atención mediática, pero no suficiente investigación. La Ley N° 21.643, que aborda la violencia laboral, incluye situaciones de desregulación emocional en estudiantes, donde el daño no es intencional. La Superintendencia de Educación reconoce los derechos laborales de los trabajadores, pero falta una regulación específica sobre cómo manejar estas situaciones. Además, la Ley Karin enfrenta desafíos al ser considerada junto a la Ley N° 21.545, que protege a personas con TEA. Es esencial que estas normativas se comprendan y socialicen para asegurar un entorno educativo seguro y justo para todos.',
    author: 'Pablo Hernández Arias',
    authorTitle: 'Family Lawyer',
    authorImg: blogAvaterImg1,
    create_at: 'Agosto, 2024',
    blogSingleImg: blogSingleImg5,
    comment: '< 10 min.',
    blClass: 'format-standard-image',
  },
];
export default blogs;