import React from 'react'
import abimg1 from '../../images/about1.jpg'
import abimg2 from '../../images/about2.jpg'


const About = (props) => {
    return (
      <section className="wpo-about-section section-padding">
        <div className="container">
          <div className="wpo-about-section-wrapper">
            <div className="row align-items-center">
              <div className="col-lg-5 col-md-12 col-12">
                <div className="wpo-about-img-wrapper">
                  <div className="wpo-about-img">
                    <img src={abimg1} alt="about1" />
                  </div>
                  <div className="wpo-about-img" style={{ marginTop: '20px' }}>
                    <img src={abimg2} alt="about2" />
                  </div>
                </div>
              </div>
              <div className="col-lg-7 col-md-12 col-12">
                <div className="wpo-about-content">
                  <div className="wpo-section-title">
                    <h2>Acerca de Nosotros</h2>
                  </div>
                  <div className="wpo-about-content-inner">
                    <p>
                      <strong>H&A Abogados</strong>, nace el año 2014, con un
                      propósito claro: acercar a nuestros principales clientes
                      de la Patagonia chilena, a las nuevas tendencias jurídicas
                      del resto del país. Lo anterior, por medio de asesorías a
                      distancia y una constante actualización, en el área del
                      derecho privado de los negocios y la prevención legal de
                      contingencias jurídicas.
                    </p>
                    <p>
                      Hace más de una década, dimos nuestros primeros pasos,
                      asumiendo la fiscalía interna de una empresa
                      estadounidense, con una significativa presencia en el
                      mercado del entretenimiento tecnológico. Desde entonces,
                      hemos ampliado nuestra asesoría y extendido nuestro
                      alcance a diversos clientes, no solo en Estados Unidos,
                      sino también en Argentina, España, Bolivia y otros países.
                    </p>
                    <p>
                      Con el tiempo, hemos avanzado en la representación de
                      personas jurídicas y naturales - nacionales y extranjeras
                      - con contingencias legales, de alta complejidad. Con
                      ello, se fue creando, una sólida trayectoria en
                      litigación, en contra de importantes instituciones
                      públicas y empresas del sector privado. Recursos de
                      protección, juicios de responsabilidad civil,
                      incumplimiento contractual y derecho laboral corporativo,
                      son sólo algunas, de las materias, que hemos defendido,
                      ante diversos tribunales nacionales, durante los últimos
                      años.
                    </p>
                    <p>
                      Consecuentemente, nuestra labor ha obtenido reconocimiento
                      en el portal del Poder Judicial, por medio de entrevistas
                      relacionadas, al área de negligencias médicas y el derecho
                      de daños. Además, una porción significativa de nuestra
                      jurisprudencia, ha sido destacada en diversos sitios
                      especializados, del derecho nacional.
                    </p>
                    <p>
                      No obstante, fue el año 2017, cuando H&A Abogados, logra
                      su consolidación, incluyendo en nuestra cartera de
                      clientes, un importante número de establecimientos
                      educacionales, que requerían de una asesoría legal
                      permanente para el año escolar. Siendo el principal
                      desafío, implementar todos los trámites administrativos y
                      jurídicos de la Ley Nº 20.845, de Inclusión Escolar y sus
                      reglamentos.
                    </p>
                    <p>
                      En el año 2018, fortalecimos el área de apertura y
                      funcionamiento de negocios, mediante la elaboración de
                      "Due Diligence" empresariales para una diversidad de
                      mercados y sectores, incluyendo constructoras,
                      restaurantes, empresas de transporte y comercio exterior.
                      Con ello, fuimos desarrollando, estrategias jurídicas
                      atractivas y efectivas, en conformidad a las
                      recomendaciones de nuestro equipo jurídico y financiero.
                    </p>
                    <p>
                      Adicionalmente, el año 2022, nuestra oficina fue
                      reforzando la prevención legal de contingencias laborales,
                      fabricando novedosos instrumentos regulatorios y
                      participando activamente, en procesos de negociación
                      colectiva.
                    </p>
                    <p>
                      Recientemente, el año 2023, aumentamos nuestros servicios,
                      e implementamos capacitaciones, remotas y presenciales, en
                      derecho educacional, ante los diversos cambios normativos
                      de los últimos años. Entre ellos, manejo de eventos
                      adversos de convivencia escolar y la implementación de la
                      Ley N° 21.545 (Ley del Espectro Autista).
                    </p>
                    <p>
                      Es cierto, hoy, año 2024, reflexionamos sobre el recorrido
                      realizado y agradecemos la confianza y compromiso de
                      nuestros clientes y colaboradores. En medio del creciente
                      cambio tecnológico, social y cultural, reconocemos la
                      necesidad de continuar perfeccionando nuestro trabajo.
                    </p>
                    <p>¡Juntémonos! Nosotros invitamos el café.</p>
                    <div className="signeture">
                      <h4>Pablo Hernández Arias (MBL)</h4>
                      <p>H&A Abogados, CEO.</p>
                      {/* <span>
                        <img src={sign} alt="" />
                      </span> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
}

export default About;