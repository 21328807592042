import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar';
import PageTitle from '../../components/pagetitle';
import Scrollbar from '../../components/scrollbar';
import blogs from '../../api/blogs';
import BlogSingle from '../../components/BlogDetails/blog5';
import Footer from '../../components/footer';
import Logo from '../../images/logo.svg';



import { Helmet } from'react-helmet';

const BlogDetails = () => {
  const BlogDetails = blogs.find((item) => item.id === '5');

  return (
    <Fragment>
      <Helmet>
        <title>
          Desregulación emocional y violencia laboral bajo la ley 21643 - H&A
          Abogados
        </title>
        <meta
          name="description"
          content="Análisis sobre la desregulación emocional y violencia laboral en el contexto de la ley 21643."
        />
      </Helmet>
      <Navbar
        hclass={'wpo-header-style-2'}
        Logo={Logo}
        topbarNone={'header-without-topbar'}
      />
      <PageTitle pageTitle={BlogDetails.title} pagesub={'Opinión'} />
      <BlogSingle />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default BlogDetails;
