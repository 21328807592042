import React from 'react'
import { Link } from 'react-router-dom';
import abimg from '../../images/about.jpg'


const About = (props) => {
    return (
      <section className="wpo-about-section section-padding">
        <div className="container">
          <div className="wpo-about-section-wrapper">
            <div className="row align-items-center">
              <div className="col-lg-5 col-md-12 col-12">
                <div className="wpo-about-img">
                  <img src={abimg} alt="" />
                </div>
              </div>
              <div className="col-lg-7 col-md-12 col-12">
                <div className="wpo-about-content">
                  <div className="wpo-section-title">
                    <h2>Acerca de Nosotros</h2>
                  </div>
                  <div className="wpo-about-content-inner">
                    <h2>
                      El éxito de nuestros clientes, determina el nuestro.
                    </h2>
                    <p>
                      H&amp;A Abogados, nace el año 2014, con un propósito
                      claro: acercar a nuestros principales clientes de la
                      Patagonia chilena, a las nuevas tendencias jurídicas del
                      resto del país. Lo anterior, por medio de asesorías a
                      distancia y una constante actualización, en el área del
                      derecho privado de los negocios y la prevención legal de
                      contingencias jurídicas.
                    </p>
                    <p>
                      Hace más de una década, dimos nuestros primeros pasos,
                      asumiendo la fiscalía interna de una empresa
                      estadounidense, con una significativa presencia en el
                      mercado del entretenimiento tecnológico. Desde entonces,
                      hemos ampliado nuestra asesoría y extendido nuestro
                      alcance a diversos clientes, no solo en Estados Unidos,
                      sino también en Argentina, España,
                      Bolivia&nbsp;y&nbsp;otros&nbsp;países.
                    </p>
                    <p>¡Juntémonos! Nosotros invitamos el&nbsp;café.</p>
                    <div className="slide-btns">
                      <Link to="/quienes-somos" className="theme-btn">
                        Ver más
                      </Link>
                    </div>
                    <div className="signeture">
                      <h4>Pablo Hernández Arias (MBL)</h4>
                      <p>H&A Abogados, CEO.</p>
                      {/* <span>
                        <img src={sign} alt="" />
                      </span> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
}

export default About;